import React from 'react';

export function requestException(error){
    return console.log(error);
}

export function accessForbiddenException(){
    document.body.innerHTML = "Ops! Área restrita para Admins. Tente: <a href=\"/login\"> efetuar login</a>";
}

export function resourceInvalidException(){
    document.body.innerHTML = "Ops! O recurso solicitado não foi encontrado.";
}

export default requestException;