import React  from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './PriceTile.scss';
import celular from './img/celular.png';
import {mask_decimal} from './util/inputMask';
import {sessionGet, setUser, logoutConfirm} from './usuario/Auth';
import cart from './img/cart.png';

class PriceTile extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      user: {}
    };

    this.setUser = setUser.bind(this);
  }

  componentDidMount(){
    let email = sessionGet('email');
    if(email != null)
    this.setUser(email);
  }

  render () {

    let REF = this;

    const getUserGreeting = () => {

      if(typeof REF.state.user === "undefined" || Object.keys(REF.state.user).length == 0)
      return '';

      let greeting = REF.state.user.gender == "M" ? "Bem-vindo, "+this.state.user.name : "Bem-vinda, "+this.state.user.name;

      return (<>{greeting} <Button variant="warning" onClick={() => logoutConfirm()} className="ml-auto logout btn-sm">SAIR</Button></>);
    }

      return (
          <section className="PriceTile">
          <Container>
          <Row> 
      <Col className="text-center wrap-cart" >
        <img src={cart} /> Carrinho: R$ {mask_decimal(this.props.amount)}
            </Col>
          </Row>
        </Container>
        </section>
      );
  }
}
  
export default PriceTile;
