import React  from 'react'; // Import React
import {getListing, CustomToggleList, tableRender} from '../../CustomDataTable'; // Import React

import {Col, Row, Container} from 'react-bootstrap';
// es6
import InfoTile from '../../InfoTile';
import Header from '../../Header';
import { Link } from "react-router-dom";

import {sessionGet} from '../../usuario/Auth';
import CFG from '../../CFG';

class ChargeDataTable extends React.Component{
  
  constructor(props) {
    super(props);
    
    this.state = {
        popoverVisible: false,
        auth: sessionGet('auth'),
        form:[],
        data: [],
        searchTerm: null,
        activeIndex: undefined
    };

    this.formUpdate = this.formUpdate.bind(this);
    this.getListing = getListing;
    this.getCustomToggleList = CustomToggleList.bind(this);
    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.tableRender = tableRender;

  }

  componentDidUpdate(prevProps, prevState){

    if(this.state.refresh != this.props.refresh){
      this.setState({ refresh: this.props.refresh });
    }

    if(prevState.refresh != this.state.refresh){
      this.updateListing();
    }
  }

  setModalShow (show, data){

    this.setState({  form : data });

    this.setState({  show: show });
  }

  updateListing = (columnsSearch) => {
    let REF = this;
    this.getListing('charge', columnsSearch).then(() => REF.setState({ loading: false }));
  }

  hideModal = () => {
    this.setState({ show: false });

    let PC = this;
    setTimeout(function(){
      PC.updateListing();
    }, 500);

  }

  componentDidMount(){
    this.updateListing();

    document.addEventListener("keydown", this.onKeyPressed, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }

  isColumnHidden = (column) => {

    if(typeof this.props.hidden_column === "undefined")
    return false;

    return this.props.hidden_column.split(',').includes(column);
  }

  getColumns = () => {
    return [
      {
        dataField: 'id',
        text: 'ID',
      //    hidden: true
      sort: true
      },{
        dataField: 'trip_name',
        text: 'Viagem',
        sort: true
      },{
        dataField: 'user_name',
        text: 'Cliente',
        sort: true
      },{
        dataField: 'method_name',
        text: 'Método',
      },{
        dataField: 'status_name',
        text: 'Status',
      },{
        dataField: 'created_at_format',
        text: 'Data',
      }
    ];
  }

  formUpdate(form_data_complete){
    this.setState({ form: form_data_complete });
  }

  onKeyPressed(event){
    if(event.keyCode == 27 )
    this.setState({ popoverVisible : false });
  }

  getCustomToggleList({
    columns,
    onColumnToggle,
    toggles
  }){
    return CustomToggleList({
      columns,
      onColumnToggle,
      toggles
    })
  }

render(){    
  return (

        <>
      <InfoTile></InfoTile>
      <Header></Header>

      <Container>
        <h4>Reservas</h4>
  <Row>
    <Col>
       <Link hidden to="/admin/charge/form" className="btn btn-info float-right">Cadastro</Link>
    </Col>
  </Row>
          {
            (() => this.tableRender('admin/charge/form'))()
          }

</Container>
  </>
  )
}
        }
        
        export default ChargeDataTable;
        