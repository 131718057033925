import React from 'react';
import axios from 'axios';
import {startSessao, sessionGet} from '../usuario/Auth';
import {CFG} from '../CFG';
import {Button, Alert} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { isValid, inputChangeHandler } from '../CustomForm';
import './Login.scss';
import logo from '../img/logo.png';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autorizado: false,
      login_ok: false,
      cnpj_existente: false,
      cnpj_inexistente: false,
      email_inexistente: false,
      razao: '',
      passoMax: 3,
      passoMin: 1,
      cnpj: '',
      email: '',
      password: '',
      empresa: '',
      grupo: '',
      validator: {},
      sem_conexao: false,
      passo: 1,
      role_id: 1,
      pagamento_pendente: false,
      empresa_bloqueada: false,
      info_passo: { 1: 'Insira Usuário/Login para acessar' }
    };

    if(typeof this.props.titlebar == "function")
    this.props.titlebar(this.props.title, this.props.description);

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.isValid = isValid.bind(this);

  }

  onKeyPressed = (event) => {
    let code = event.keyCode || event.which;

    if(code === 13) { //13 is the enter keycode
    this.submitLogin()
    }  
  }
  
  componentDidMount(){

    let k = sessionGet('key');

    if(k !== null){
     
    }

    document.addEventListener("keydown", this.onKeyPressed, false);

  }
 
  componentDidUpdate(prevProps, prevState){

    if(prevState.email != this.state.email || prevState.password != this.state.password){
      this.setState({ email_inexistente: false });
    }

    if(prevState.login_ok != this.state.login_ok && this.state.login_ok){
      this.doLogin();
    }

  }

  doLogin = () => {
    startSessao( this.state.email, this.state.password, (this.state.role_id == 10 ? "/admin" : "/charges"));
  }

  pagamentoPendente = () => {
    return (
      <Alert key={1} variant={"warning"}>
   Pagamento consta pendente. Efetue o pagamento e/ou entre em contato com o suporte.
    </Alert>
    );
  }

  semConexao = () => {
    return (
      <Alert key={1} variant={"warning"}>
    Não foi possível conectar ao banco
    </Alert>
    );
  }

  emailNotFound = () => {
    return (
      <Alert key={1} variant={"warning"}>
    Este usuário não consta na base de dados
    </Alert>
    );
  }

  passoLogin = () => {
    let Lg = this;
    return (<div className="form-group">
      <h3>{Lg.state.razao}</h3>
      <div className="text-right w-100">
      <Link to="/login-recuperar" className="text-muted"><small>Esqueci minha senha</small></Link>
      </div>
      <input id="login-username" placeholder="E-mail" type="text" name="email" required  className="input-material form-control" value={this.state.email} onChange={this.inputChangeHandler} />
      <input id="login-pass" placeholder="password de 4 dígitos" type="password" name="password" required className="input-material form-control" value={this.state.password} onChange={this.inputChangeHandler} />
      {
          (() => {

            if(Lg.state.email_inexistente)
            return Lg.emailNotFound()

            if(Lg.state.sem_conexao)
            return Lg.semConexao()

          })()
      } 
     <div>
    <Button onClick={this.submitLogin} className="mt-2 float-right"><i className="fa fa-arrow-right"></i> Entrar</Button>
   </div>
   </div>);
  }

  submitLogin = () => {
    let Lg = this;

    return axios.post(CFG.URL_API+'/login', { 
      email: this.state.email,
      password: this.state.password })
    .then(function(response){

      if(response.data.success == true){
        Lg.setState({ login_ok: true, role_id: response.data.message.role_id });
      }
      else
      Lg.setState({ email_inexistente: true });
      //Perform action based on response
    })
    .catch(function(error){
      Lg.setState({ email_inexistente: true });
      //Perform action based on error
    });
  }

  render() {
    let Lg = this;

    let logout = window.localStorage.getItem('logout_confirm');
    window.localStorage.removeItem('logout_confirm');

    return (
      <>
      <Alert variant="info" hidden={!logout}>Sessão encerrada com sucesso</Alert>
     <div className="page login-page">
  <div className="container d-flex align-items-center">
    <div className="form-holder has-shadow">
      <div className="row">
        {/* Logo & Information Panel*/}
       
        {/* Form Panel    */}
        <div className="col-lg-6 bg-white">
          <div className="form h-100 d-flex align-items-center">
           <div className="w-100 align-self-center align-center">
           <div className="w-100">
             <Link to="/">  <img src={logo} className="d-block" /></Link>
           </div>
            
            <div className="content-flex d-block">
              {
                Lg.passoLogin()
              }
            </div>
            </div>
            </div>
          </div>
        <div className="col-lg-6 d-none d-md-block">
          <div className="info d-flex align-items-center">
            <div className="content">
              <div className="logo">
                <h1>Meu acesso</h1>
              </div>
              <p>{this.state.info_passo[this.state.passo]}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  </>
    );
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }

}
export default LoginForm;