import React from 'react';
import CFG from './CFG';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Header.scss';
import wp from './img/wp.png';
import {Link} from 'react-router-dom';
import acesso from './img/acesso.png';
import encontre_viagem from './img/encontre-viagem.png';
import logo from './img/logo.png';
import pathMap from './util/pathMap';
import { sessionGet, isAdmin, setUser } from './usuario/Auth';

class Header extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            auth: sessionGet('key'),
            user: {
                id: undefined
            }
        };

        this.isAdmin = isAdmin.bind(this);
        this.setUser = setUser.bind(this);

    }

    componentDidMount = () => {
        this.isAdmin();
    }

    render () {
        let homelink_hidden = pathMap()[1] == "";
        let charges_hidden = pathMap()[1] == "charges" || pathMap()[1] == "admin";
        let search_hidden = pathMap()[1] == "search";

    return (
        <header className="Header py-4">
            <Container>
            <Row>
                    <Col md={4} className="py-3 logo d-flex">
                    <a href="/" hidden={homelink_hidden}>
                        <img src={logo} alt="logo" className="img-fluid" />
                    </a>
                    <img hidden={!homelink_hidden} src={logo} alt="logo" className="img-fluid" />

                    </Col>
                    <Col md={2}>
                        <Row>
                            <Col xs md={6} className="py-3">
                           
                            </Col>
                            <Col xs md={6} className="py-3">
                         
                            </Col>
                        </Row>
                </Col>
                    <Col  md={3} xs={6} className={search_hidden ? 'd-none' : 'd-flex wp-call'} >
                        <a href={CFG.URL_APP+"/search"} className="py-3 ml-auto">
                            <img src={encontre_viagem} alt="logo" className="img-fluid" />
                        </a>
                    </Col>
                    <Col  md={3} xs={6} className="wp-call" className={charges_hidden ? "d-none" : "d-flex"} >
                        <a href={this.state.auth != null ? CFG.URL_APP+"/charges" : CFG.URL_APP+"/login"} className="py-3 ml-auto">
                            <img src={acesso} alt="logo" className="img-fluid" />
                        </a>
                    </Col>
                 
                </Row>
                <Row hidden={!this.state.is_admin}>
                    <Button variant="primary"><Link to="/admin">ADMIN</Link></Button>
                </Row>
            </Container>
        </header>
    );
    }
}

export default Header;
