import axios from 'axios';
import xmljs from 'xml-js';
import {CFG, axiosHeader} from './CFG';

export const psRealToken = "d86afdd1-5db0-41ec-b3ea-06fadd579d1c825a06a1472a9db8bf7e7fe903829f4947bc-345f-4f0c-a28c-17ac6b3fd69b";
export const psEmail = 'baixinhoviagem@gmail.com';
export const psToken = '718343BB38D64657918CC616825699A8';

//Matteus
//export const psRealToken = "569cc290-3878-44f5-a067-05ee7e2f977feb4041304a59b8fd14920ee1983f92be182d-6a22-46f4-a35d-62827c3b2dd0";
//export const psEmail = 'teusmatteus@gmail.com';
//export const psToken = 'BFE19F6EEAEF4455BFD2E35A866AF27C';
//export const psNotificationUrl = 'https://desenvolvedormatteus.com.br/pagseguro.php';
export const psNotificationUrl = 'https://api.trip.matteus.dev/pagseguro-update';
export const psHeader = {
  responseType: 'text',
  headers: {"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"}
};

var PagSeguroDirectPayment = null;

    export function loadPagSeguroScripts(){
        let src = 'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js';
var tag = window.document.createElement('script');
console.log(tag);
tag.async = true;
tag.src = src;
window.document.getElementById('head').appendChild(tag);

    }

    export function buildCheckout () {

        let REF = this;

      window.PagSeguroDirectPayment.onSenderHashReady(function(response){
        if(response.status == 'error') {
            console.log(response.message);
            return false;
        }
        var senderHash = response.senderHash; //Hash estará disponível nesta variável
        REF.setState({ senderHash });
    });

          //O método createCardToken utiliza os dados do cartão de crédito para gerar um token que será 

  }  

  //step 3 - finish checkout

 export function checkout () {

    let REF = this;

   let data = this.psPayloadString();

   let xhr = new XMLHttpRequest();

   //retorno apenas na url de notificação
   axios.post(CFG.URL_API+'/pagseguro-checkout', {data}, axiosHeader())
   .then((response) => {

    if(typeof response.data.error !== "undefined"){
      //volte para etapa de pagto.
      REF.setState({ step_key: 3 });
      alert(response.data.message);
    }
    else
    REF.setState({ paymentLink: response.data.payment_link[0] });
   })
   .catch((error) => console.log(error));

}

export function displayPsError(response){

  let error = '';
  for(const [k,e] of Object.entries(response.errors)){
    switch(k){
      case "30400":
        error = ('Confira todos os dados do cartão: número, mês, ano, cvv do cartão novamente');
      break;
      default:
        error = ('Confira se há alguma informação do cartão incorreta \n Corrija para prosseguir com sua reserva.');
      break;
    }

    return error;
    
    }
}
   
export default buildCheckout;
