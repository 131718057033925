import React from 'react';


export const settingAmountTable = {
        age : {
           "0": {
                'name' : 'de 0 a 5 anos',
                'min' : 0,
                'max' : 5
            },
            "1": {
                'name' : 'de 6 a 9 anos',
                'min' : 6,
                'max' : 9
            },
          "2":  {
                'name' : 'de 6 a 10 anos',
                'min' : 6,
                'max' : 10
            },
           "3": {
                'name' : 'de 11 a 12 anos',
                'min' : 11,
                'max' : 12
            },
            "4": {
                'name' : 'Adultos, a partir de 11 anos',
                'min' : 11
            },
        },
        room : {
           "0":  {
                'name' : 'Triplo'
            },
           "1": {
                'name' : 'Duplo'
            }
        },
        boarding : {
            "0": {
                'name' : 'Barreiro'
            },
            "1": {
                'name' : 'Praça da CEMIG'
            },
            "2": {
                'name' : 'Terminal turístico JK'
            },
        },
        discount: {
            "0" : {
                'key': 'is-student',
                'name': "Sou estudante"
            },
            "1" : {
                'key': 'is-old',
                'name': "Sou idoso(a)"
            }
        }
};

export default settingAmountTable;