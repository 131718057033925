import React from 'react';
import './Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import url_btn_close from './img/icons/close.png';

class TestimonialModal extends React.Component{

    constructor(props) {
      super(props);

      this.state = {  
        form: {},
        loading: false,
        data_removido_format: undefined,
        allow_delete: false
      };

    }
    
  componentDidMount(){  
   
    document.addEventListener("keydown", this.onKeyPressed, false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }

  render () {
    return (
      <>
      <Modal   
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
          Avaliação
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />

        </Modal.Header>
        <Modal.Body style={{ height: '90vh' }}>        
            <img src={this.props.src} className="img-fluid m-auto d-block img-thumbnail img-rounded"/>
        </Modal.Body>
   
      </Modal>
      </>
    );
  }

}

export default TestimonialModal;