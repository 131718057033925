import React from 'react';
import './App.css';
import './bootstrap.min.css';
import NavbarTop from './NavbarTop.js';
import Trips from './Trips.js';
import InfoTile from './InfoTile';
import Header from './Header';

class Search extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
     
            form: {}
     
        };
    
    }
    
    formControl = (formRef) => {
      delete formRef.form;
      delete formRef.list;
      this.setState({ form: formRef });
    }
    
  render(){

    return (
            <>
               <InfoTile></InfoTile>
               <Header></Header>
              <NavbarTop formcontrol={this.formControl}></NavbarTop>

            <Trips id={this.state.form.id} start_at={this.state.form.start_at} end_at={this.state.form.end_at} />
            </>
    );
  }

}

export default Search;
