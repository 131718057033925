import React from 'react';
import axios from 'axios';
import CFG from '../CFG';

export function e(){
    return null;
}

export function empresa_lookup(id){
    return axios.get(CFG.URL_API+'/empresa/'+id);
}

export function usuario_lookup(id){
    return axios.get(CFG.URL_API+'/user/'+id);
}

export default e;