import React from 'react';
import axios from 'axios';
import {axiosHeader, CFG} from '../../CFG';
import { inputChangeHandler, dateTimeChangeHandler, isValid, nascimentoChangeHandler, checkboxClickHandler, selectChangeHandler, inputBlurHandler, cepChangeHandler } from '../../CustomForm';
import InfoTile from '../../InfoTile';
import Header from '../../Header';
import { sessionGet, sessionCheckAdmin, setUser } from '../../usuario/Auth';
import {Row,Container, Button, Col, InputGroup, FormControl, Card} from 'react-bootstrap';
import DestinationSelect from '../destination/DestinationLookup';
import {resourceInvalidException} from '../../util/exception';
import {settingAmountTable} from '../Settings';
import './TripForm.scss';
import {inputValidate}  from '../../util/inputValidate';
import {mask_decimal, parseDecimal} from '../../util/inputMask';
import DateTimePicker from 'react-datetime-picker';
import {parseUS, objectToDate} from '../../util/date';
/*
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import {htmlToDraft, convertFromRaw} from 'html-to-draftjs'; 

// es modules
import { Editor } from '@tinymce/tinymce-react'; */
import CKEditor from 'ckeditor4-react';

class TripForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      destination_id: undefined,
      amount_table: settingAmountTable,
      user: {
        id: undefined,
      },
      destination: {
        id: 0
      },
      file_id: undefined,
      file:{
        url: undefined
      },
      icone_select_id: '',
      icone_select_url: '',
      validator: {},
      empresa: sessionGet('empresa'),
      icone: 0,
      grupo: 0,
      subgrupo: 0,
      categoria: 0,
      icone: '',
      unique: '',
      codigo: '',
      sittrib: '',
      ncm: '',
      details: '',
      unidade: '',
      custo: '',
      estoque: '',
      alterado: '',
      terminal: '',
      ativo: '',
      excluido: '',
      promocional: '',
      vias: '',
      materiaprima: '',
      producao: '',
      barras: '',
      cst: '',
      cfop: '',
      icms: '',
      mva: '',
      ipi: '',
      cofins: '',
      icms_st: '',
      cfop_entrada: '',
      pis: '',
      ean: '',
      tara: '',
      estoquefundo: '',
      estoqueperda: '',
      estoqueproduto: '',
      user_id: '',
      contract: '',
      query_success: false,
      item_legend: {
        boarding: 'Embarque', 
        room: 'Hospedagem', 
        age: 'Faixa etária'
      }
    };

    this.checkboxClickHandler = checkboxClickHandler.bind(this);
    this.dateTimeChangeHandler = dateTimeChangeHandler.bind(this);
    this.sessionCheckAdmin = sessionCheckAdmin.bind(this);
    this.setUser = setUser.bind(this);
    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.selectChangeHandler = selectChangeHandler.bind(this);
    this.nascimentoChangeHandler = nascimentoChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);
    this.cepChangeHandler = cepChangeHandler.bind(this);
    this.isValid = isValid.bind(this);
    this.setCurrentIcon = this.setCurrentIcon.bind(this);
    this.queryIconeUrl = this.queryIconeUrl.bind(this);

  }

  uploadClick = (e) => {
    this.inputElement.click();
    }

    fileChangeHandler = (files) => {

      if(files.length == 0)
      return;
    
        this.setState({
          file: files[0]
       });
  
    }

   deleteFile = () => {

    let REF = this;

    axios.delete(CFG.URL_API+'/trip/'+this.state.id+'/delete-file', axiosHeader())
    .then(function(response){
    
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(() => {
      REF.setState({ file_id: undefined, file: { url: undefined, id: undefined } });
    });
   }

  componentDidMount(){

    if(typeof this.props.id !== "undefined"){
      this.setState({ id: this.props.id });
   
    } else {
      this.renderContract();
    }

    let REF = this;
    this.setUser(sessionGet('email')).then(u => REF.sessionCheckAdmin());

  }

  componentDidUpdate(prevProps, prevState){

    if(prevState.id !== this.state.id){
      this.get();
    } 

    if(prevState.start_at !== this.state.start_at
      || prevState.end_at !== this.state.end_at){

        if(this.state.start_at !== null){
         
          let cal_start_at = objectToDate(parseUS(this.state.start_at));
    
          this.setState({ cal_start_at });
        } else {
          this.setState({ start_at: null, cal_start_at: '' });
        }

        if(this.state.end_at !== null){
         
          let cal_end_at = objectToDate(parseUS(this.state.end_at));
    
          this.setState({ cal_end_at });
        } else {
          this.setState({ end_at: null, cal_end_at: '' });
        }

      }

      if(this.state.user !== null && typeof this.state.user.id !== "undefined" && prevState.user.id !== this.state.user.id){
        let REF = this;
        this.setState({ user_id: REF.state.user.id });
      }

  }

  queryIconeUrl(icone_id){

    let PF = this;
    axios.get(CFG.URL_API+'/icone/'+icone_id, axiosHeader())
    .then(function(response){

      PF.setCurrentIcon( response.data.id, response.data.icone );    
      
      //Perform action based on response
    })
    .catch(function(error){
      //Perform action based on error
    });
   }

  setCurrentIcon(id, url) {
    this.setState({ icone_select_id: id, icone_select_url: url });
  }

  extrasChangeHandler = (event, type, choice, value) => {

    event.persist();
    let REF = this;
    let up_amount_table = this.state.amount_table;

    if(typeof type === "undefined")
    return null;

    if(typeof up_amount_table[type] === "undefined")
    up_amount_table[type] = [];

    if(typeof up_amount_table[type][choice] === "undefined")
    up_amount_table[type][choice] = 0;

    let mask_me = mask_decimal(event.target.value);

      REF.setState({ amount_table: {
        ...REF.state.amount_table,
      [type]: {
        ...REF.state.amount_table[type],
         [choice]:{
           ...REF.state.amount_table[type][choice],
            amount: mask_me
          }
        }
      }});

      setTimeout(() => inputValidate(event), 1000);
  
  }

  cleanUpArray = (arr) => {
    let up_arr = arr.filter((e, k) => {
        return (typeof e !== "undefined");
    });

    return up_arr;
  }

  disableOption = (event, type, key, name) => {
      event.persist();

      let REF = this;

      this.checkboxClickHandler(event);
      
      let up_at = {};
      Object.assign(up_at, this.state.amount_table);

      if(event.target.checked){
       // delete up_at[type][key];
        up_at[type][key] = {amount: '', name: name, disabled: "true"};
      } else {

        if(typeof up_at[type] === "undefined"){
          up_at[type] = {};
        }

        up_at[type][key] = {amount: 0, name: name};
      }

      this.setState({ amount_table: {
        ...up_at, [type]: {
          ...up_at[type], [key]: {
            ...up_at[type][key]
          }
        }
      } });

      this.checkboxClickHandler(event);
      
  }

  tripCartList = () => {

    let REF = this;

    let price_table = Object.entries(this.state.amount_table);

    return price_table.map(([k, list]) => {

      let list_entries = Object.entries(list);

     return (<>
      { list_entries.map(([n, price]) => {

        let amount = '';
        if(typeof REF.state.amount_table[k] !== "undefined" 
        && typeof REF.state.amount_table[k][n] !== "undefined" 
        && REF.state.amount_table[k][n] !== null){
          amount = REF.state.amount_table[k][n].amount;
        }
       
        return (
          <Row className="item" key={n}>
            <Col md={6} className="d-flex">
              <label className="my-auto"> + {REF.state.item_legend[k]} {price.name}:  </label>
              </Col>
              <Col md={3}>
              <label htmlFor={`f-amount_${k}_${n}`}>
              {k === "discount" ? "Desconto" : "Valor"}
            </label>
            <InputGroup className="mb-3">
    <InputGroup.Prepend>
      <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
    </InputGroup.Prepend>
    <FormControl readOnly={price.disabled === "true"}
     value={amount} name={k} id={`f-amount_${k}_${n}`} type="text" onChange={ (event) => REF.extrasChangeHandler(event, k, n, event.target.value)}
    />
  </InputGroup>

              </Col>
              <Col md={3} className="d-flex">
                <label className="my-auto">
                  <input type="checkbox" name={`disabled_${k}_${n}`} checked={price.disabled === "true"} value="1" onClick={(event) => this.disableOption(event, k, n, price.name)} />
                 Desabilitar </label>
              </Col>
         </Row>
        );
      } ) 
    }
     
    </>);
  } );

  }

  renderContract(){
    let REF = this;
    axios.get(CFG.URL_API+'/get-contract', axiosHeader()).then(e => REF.setState({ contract_base: e.data, contract: e.data }) );
  }

  onEditorChange = ( evt ) =>  {
    this.setState( {
        contract: evt.editor.getData()
    } );
}

  render(){

    let REF = this;

    return (
      <>
      <InfoTile></InfoTile>
      <Header></Header>

      <Container>

      <fieldset className="mt-4">
        <legend>
        {typeof this.state.id === "undefined" ? "Nova" : "Editar"} Viagem <span className="badge badge-info">{typeof this.state.id === "undefined" ? "" : this.state.id }</span>
        </legend>

  <hr/>
<h4>Datas</h4>
          <Row>
            
              <Col md="6">
                  <label className="d-block">Saída </label>
                  <DateTimePicker locale="pt-BR" format="dd/MM/y HH:mm" autoComplete="off" name="cal_start_at" id="f-start_at" value={this.state.cal_start_at} onChange={(date) => REF.dateTimeChangeHandler(date, 'start_at')} />
              </Col>
                  <Col md="6">
                  <label className="d-block">Volta</label>
                  <DateTimePicker locale="pt-BR" format="dd/MM/y HH:mm" autoComplete="off" name="cal_end_at" id="f-end_at" value={this.state.cal_end_at} onChange={(date) => REF.dateTimeChangeHandler(date, 'end_at')} />
              </Col>
              </Row> 

  <Row className="my-3">

    <Col md="4">
    <label>Destino</label>
      <DestinationSelect id={this.state.destination_id} change={this.selectChangeHandler} formcontrol={this.formControl} />
    </Col>

    <div className="form-group col-md-4">         
          <label htmlFor="f-title required">
            Nome da viagem
          </label>
          <input className="form-control text-uppercase" name="title" id="f-title" type="text" value={this.state.title} onChange={this.inputChangeHandler}  />
        </div>

        <div className="col-md-4" hidden={typeof this.state.id === "undefined"}>
        <label htmlFor="required">
           Data do cadastro
          </label>
          <input className="form-control" name="created_at_format" type="text" value={this.state.created_at_format} readOnly/>
        </div>
  </Row>

  <Row>
        <div className="form-group col-md-4 col-6">         
          <label>
            Viagem ativa
            </label>
            <br/>
            <ul className="list-inline">
              <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="ativo" className="custom-control-input" id="f-ativo-sim" type="radio" value="S" checked={this.state.disabled_at === null || typeof this.state.disabled_at === "undefined"} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler}/>
                  <label className="custom-control-label" htmlFor="f-ativo-sim">
Sim   </label>
                  </div>
                </li>
                <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="ativo" className="custom-control-input" id="f-ativo-nao" type="radio" value="N" checked={this.state.disabled_at !== null} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler}/>
                  <label className="custom-control-label" htmlFor="f-ativo-nao">
Não    </label>
                  </div>
                </li> 
            </ul>
        </div> 
 
        <div className="col-md-4">
        <label htmlFor="f-charge_min required">
           Mínimo de pessoas
          </label>
          <input className="form-control" name="charge_min" id="f-charge_min" type="number" value={this.state.charge_min} onChange={this.inputChangeHandler}/>
        </div>

        <div className="col-md-4">
        <label htmlFor="f-charge_max required">
           Lotação
          </label>
          <input className="form-control" placeholder="Número de pessoas" name="charge_max" id="f-charge_max" type="number" value={this.state.charge_max} onChange={this.inputChangeHandler}/>
        </div>

        </Row>   

        <div className="row">   

<div className="col-md-12">
<label htmlFor="f-details required">
    Descrição 
  </label>
  <textarea className="form-control" name="details" id="f-details" onChange={this.inputChangeHandler}value={this.state.details} />
</div>
</div>
</fieldset>

<fieldset className="mt-4">
        <legend>
         Contrato
        </legend>
<div className="row">   

<div className="col-md-12">
<label htmlFor="f-contract required">
     
  </label>

  <CKEditor   onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) } name="contract"  onChange={this.onEditorChange} type="classic" data={this.state.contract} />

</div>
</div>
</fieldset>

<fieldset className="mt-4">
        <legend>
         Estatísticas
        </legend>
<Row>

<div className="col-md-4" hidden={typeof this.state.id === "undefined"}>

      <label >
         Receita gerada (R$)
        </label>
        <input readOnly={true} className="form-control" type="text" value={this.state.charge_sum > 0 ? this.state.charge_sum : "Ainda não há registros"}/>
      </div>

</Row>
</fieldset>
  <fieldset className="mt-4">
        <legend>
          Imagem destacada
        </legend>
<Card>
  <Card.Body>
  <Row>
      <Col xs={4} className="download" hidden={typeof this.state.file.name === "undefined"}>
        <a target="_blank" href={this.state.file.url} title="Clique para baixar o file" className="btn btn-primary btn-sm">
        
          <i className="fa fa-download"></i> {this.state.file.name}
        </a>
      </Col>

      <Col xs={4} hidden={typeof this.state.file.name === "undefined"}>
          Imagem escolhida: {this.state.file.name}
      </Col>
   
      <Col className="upload" xs={4}  hidden={typeof this.state.file.name !== "undefined"}>
        <Button title="Clique para subir um arquivo" onClick={this.uploadClick} className="btn-sm" variant="warning"><i className="fa fa-camera"></i> Escolher imagem</Button>

        <input hidden ref={input => this.inputElement = input} type="file" name="anexo" onChange={ (e) => this.fileChangeHandler(e.target.files) }  />
        </Col>
      
      <Col xs={4} className="download" hidden={typeof this.state.file.name === "undefined"}>
         <Button variant="danger" onClick={REF.deleteFile}><i className="fa fa-close"></i> Remover imagem</Button>
      </Col>
      </Row>
  </Card.Body>
</Card>
</fieldset>
      <fieldset className="mt-4">
        <legend>
          Tabela de preços
        </legend>
     
    {this.tripCartList()}
  
    </fieldset> 
    
<Row className="mt-2">
<Col className="d-flex">

<Button variant="danger" to="/admin/trip" hidden={typeof this.state.id === "undefined"} className="mr-auto" onClick={this.confirmRemove} >Remover</Button>
<Button onClick={() => this.submitForm()} className="ml-auto">Salvar</Button>
</Col>

</Row>
      </Container>
      </>
    );
  }
  
  confirmRemove = () => {
    if(window.confirm("Tem certeza de que deseja remover "+this.state.title+"?")){
        this.delete(); 
    }
  }

  submitForm = () => {
    return typeof this.state.id !== "undefined" ? this.put() : this.post();
  } 

  get = () => {
    let REF = this;
    axios.get(CFG.URL_API+'/trip/'+this.state.id, axiosHeader())
    .then(function(response){

      if(response.data == null){
        REF.setState({ query_success: false });
        return null;
      }

      let parse = JSON.parse(response.data.amount_table);

      let a_t = {...settingAmountTable, ...parse};
      
      let jp = REF.formatAmountTable(a_t, true);

      //causa sobreposição. precisa deletar
      delete response.data.user;

        REF.setState({ ...response.data, 
          amount_table: jp,         
          query_success: true });
    
      //Perform action based on response
  }).catch((err) => resourceInvalidException());
   }

   formatAmountTable = (amount_table, mask = false) => {
    let REF = this;
    let up_state = {};

    let price_table = Object.entries(amount_table);

    price_table.map(([k, list]) => {

      if(list === null)
      return null;

      let list_entries = Object.entries(list);

     return list_entries.map(([n, price]) => {
  
       if(typeof up_state[k] === "undefined"){
        up_state[k] = {};
       }
     
      // if(typeof up_state[k][n] === "undefined")
     //  up_state[k][n] = {};

      // if(price === null || typeof price.amount === "undefined")
      // return undefined;

       let amount_input = typeof price.amount !== "undefined" ? price.amount : "0";
       if(typeof price === "object"){
         up_state[k][n] = price;
         price.amount = mask ? mask_decimal(parseDecimal(amount_input)) : parseDecimal(amount_input);
       }

      });

    });

   return up_state;
      
   }

   stateFormat = () => {

    this.state.amount_table = JSON.stringify(this.formatAmountTable(this.state.amount_table));

    return this.state;
   }

   saveImage = (trip_id) => {
    let headers = axiosHeader();

    let formData = new FormData();
    
    formData.append('file', this.state.file);
    formData.set('user_id', this.state.user_id);
    formData.set('_method', 'PUT');

    axios.post(CFG.URL_API+'/trip/'+trip_id, formData, axiosHeader())
    .then(function(response){
      window.alert('Registro salvo');
      window.location.href=CFG.URL_APP+"/admin/trip/form/"+response.data.id;
    });
   }

   post = () => {
    let REF = this;
 
    let post_data = this.stateFormat();

    delete post_data.id;
  
    axios.post(CFG.URL_API+'/trip', post_data, axiosHeader())
    .then(function(response){
      REF.saveImage(response.data.id);    

      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

   put = () => {
    let REF = this;
    let post_data = this.stateFormat();
    axios.put(CFG.URL_API+'/trip/'+this.state.id, post_data, axiosHeader())
    .then(function(response){
      REF.saveImage(response.data.id); 
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
   delete = () => {

    axios.delete(CFG.URL_API+'/trip/'+this.state.id, axiosHeader())
    .then(function(response){
      window.location.href=CFG.URL_APP+"/admin/trips";
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
}

export default TripForm;
