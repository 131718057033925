import React from 'react';
import Login from './usuario/Login';
import LoginRecuperar from './usuario/LoginRecuperar';
import { Route,  } from "react-router-dom";
import { CacheSwitch } from 'react-router-cache-route';
import Search from './Search';
import Landing from './Landing';
import Charges from './Charges';
import Admin from './admin/Admin';
import TripDataTable from './admin/trip/TripDataTable';
import TripForm from './admin/trip/TripForm';
import ChargeForm from './admin/charge/ChargeForm';
import DestinationForm from './admin/destination/DestinationForm';
import ChargeDataTable from './admin/charge/ChargeDataTable';
import DestinationDataTable from './admin/destination/DestinationDataTable';
import Testimonials from './Testimonials';
import FormDraw from './FormDraw';

export function Routes(props){
    return (
        <>
          <CacheSwitch>
            <Route path="/admin/charge/form/:id" render={(rProps) => <ChargeForm {...rProps.match.params}/>} title="Informações da reserva" description="" titlebar={props.titlebar} />
            <Route path="/admin/charges"  render={() => <ChargeDataTable title="Gerenciar viagens" description="" titlebar={props.titlebar} />} />
            <Route path="/admin/trips"  render={() => <TripDataTable title="Gerenciar viagens" description="" titlebar={props.titlebar} />} />
            <Route path="/admin/trip/form/:id?" render={(rProps) => <TripForm {...rProps.match.params}/>} title="Informações da viagem" description="" titlebar={props.titlebar}   />
            <Route path="/admin/destinations"  render={() => <DestinationDataTable title="Gerenciar destinos" description="" titlebar={props.titlebar} />} />
            <Route path="/admin/destination/form/:id?" render={(rProps) => <DestinationForm {...rProps.match.params}/>} title="Informações do destino" description="" titlebar={props.titlebar}   />
            <Route path="/admin"  render={() => <Admin title="Admin" description="" titlebar={props.titlebar} />} />
            <Route path="/login"  render={() => <Login title="Login" description="" titlebar={props.titlebar} />} />
            <Route path="/login-recuperar"  render={() => <LoginRecuperar title="Recuperar" description="" titlebar={props.titlebar} />} />
            <Route path="/charges"  render={() => <Charges title="Minhas Reservas" description="" titlebar={props.titlebar} />} />
            <Route path="/avaliacao"  render={() => <Testimonials title="Minhas Reservas" description="" titlebar={props.titlebar} />} />
            <Route path="/formulario-sorteio"  render={() => <FormDraw title="Participar do sorteio" description="" titlebar={props.titlebar} />} />
    
          <Route path="/search"  render={() => <Search title="Inicial" description="" titlebar={props.titlebar} />} />
          <Route path="/"  render={() => <Landing title="Inicial" description="" titlebar={props.titlebar} />} />
            <Route path="*">
             <NoMatch />
            </Route>
        </CacheSwitch>
        </>
    );
}

function NoMatch() {
  
    return (
      <div>
        <h3>
          No match for <code>{window.location.pathname}</code>
        </h3>
      </div>
    );
  }

export default Routes;