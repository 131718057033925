import React from 'react';
import axios from 'axios';
import {validate_datetime, inputValidate}  from './util/inputValidate';
import inputMask from './util/inputMask';
import './CustomForm.scss';

export default function e(){
  return true;
}

export function cepChangeHandler(event) {
  var CF = this;
  let cep_format = event.target.value.replace(/\D/g, '');

  if(cep_format.length == 8){
    axios.get('https://viacep.com.br/ws/'+cep_format+'/json/')
    .then(function(response){
      CF.setState({
        cep_updated:true,
        cidade: response.data.localidade,
        bairro: response.data.bairro,
        estado: response.data.uf,
        uf: response.data.uf,
        logradouro: response.data.logradouro,
      });
    });
  }

  if(typeof this.inputChangeHandler === "function")
  this.inputChangeHandler(event);
}

export function dateTimeChangeHandler (date, target_name) {

  if(date === null){
    this.setState({ ["cal_"+target_name]: null, [target_name]: null });
    return null;
  }

  let dt = date;
  let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0')+' '+dt.getHours().toString().padStart(2, '0')+':'+dt.getMinutes().toString().padStart(2, '0')+":00";
  
  if(!validate_datetime(dt_format)){
    return null;
  }

  this.setState({ ["cal_"+target_name]: dt, [target_name]: dt_format }); 
}

export function inputChangeHandler(event) {

  this.setState({ [event.target.name] : inputMask(event.target) });
  
  let REF = this;

  event.persist();

  setTimeout(() => {
    let validate = inputValidate(event);

    REF.state.validator[event.target.name] = validate;
    if(typeof REF.props.modalcontrol === "function")
    REF.props.modalcontrol(REF);

  }, 500);
}
export function nascimentoChangeHandler(date) {
  this.setState({ nascimento : date });
}

export function vencimentoChangeHandler(date) {
  this.setState({ dt_vencimento : date });
}

export function inputBlurHandler(event){
  /* let validate = inputValidate(event);
  this.state.validator[event.target.name] = validate;
  if(typeof this.props.modalcontrol === "function")
  this.props.modalcontrol(this); */
}

export function checkboxClickHandler(event){
  console.log(event);
  if(typeof this.state[event.target.name] == "undefined"){
    this.setState({ [event.target.name] : event.target.value });
  } else {
    this.setState({ [event.target.name] : undefined });
  }

  this.state.validator[event.target.name] = true;
  if(typeof this.props.modalcontrol === "function")
  this.props.modalcontrol(this);
}

export function radioSwitchHandler(event){

  this.setState({ [event.target.name] : event.target.value });

  this.state.validator[event.target.name] = true;
  
  if(typeof this.props.modalcontrol === "function")
  this.props.modalcontrol(this);
}

export function selectChangeHandler(pair, actionMeta){
  this.setState(state => {
    state[actionMeta.name] = pair != null ? pair.value : null; 
  });

 // let validate = inputValidate(pair);
  this.state.validator[actionMeta.name] = true;
}

export function isValid(){
  let fields = Object.entries(this.state.validator);
  let invalid = fields.find(function(field) {
    return field[1] === false;
  });
  return invalid === undefined;
}
