import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from '../Routes.js';

class LayoutWide extends React.Component{

  updateTitleBar = (title, description) => {
    this.setState({ page: {  title, description } });
}

  render() {
    
    return (
            <Router basename={process.env.PUBLIC_URL}>
                <Routes  titlebar={this.updateTitleBar}/>
            </Router>
    );
  }
}
  
  export default LayoutWide;