import React from 'react';
import axios from 'axios';
import {axiosHeader, CFG} from '../../CFG';
import { inputChangeHandler, isValid, nascimentoChangeHandler, checkboxClickHandler, selectChangeHandler, inputBlurHandler, cepChangeHandler } from '../../CustomForm';
import InfoTile from '../../InfoTile';
import Header from '../../Header';
import { sessionGet, sessionCheckAdmin, setUser } from '../../usuario/Auth';
import {Row,Container, Button, Col, InputGroup, FormControl} from 'react-bootstrap';
import DestinationSelect from '../destination/DestinationLookup';
import {resourceInvalidException} from '../../util/exception';
import {settingAmountTable} from '../Settings';
import './DestinationForm.scss';
import inputValidate  from '../../util/inputValidate';
import {mask_decimal, parseDecimal} from '../../util/inputMask';

class DestinationForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      destination_id: 0,
      user: {
        id: undefined
      },
      validator: {},
      show: false,
      icone: 0,
      grupo: 0,
      subgrupo: 0,
      categoria: 0,
      icone: '',
      unique: '',
      codigo: '',
      sittrib: '',
      ncm: '',
      details: '',
      unidade: '',
      custo: '',
      estoque: '',
      alterado: '',
      terminal: '',
      ativo: '',
      excluido: '',
      promocional: '',
      vias: '',
      materiaprima: '',
      producao: '',
      barras: '',
      cst: '',
      cfop: '',
      icms: '',
      mva: '',
      ipi: '',
      cofins: '',
      icms_st: '',
      cfop_entrada: '',
      pis: '',
      ean: '',
      tara: '',
      estoquefundo: '',
      estoqueperda: '',
      estoqueproduto: '',
      charge_max : '',
      query_success: false,
      item_legend: {
        boarding: 'Embarque', 
        room: 'Hospedagem', 
        age: 'Faixa etária'
      }
    };

    this.checkboxClickHandler = checkboxClickHandler.bind(this);
    this.sessionCheckAdmin = sessionCheckAdmin.bind(this);
    this.setUser = setUser.bind(this);
    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.selectChangeHandler = selectChangeHandler.bind(this);

    this.isValid = isValid.bind(this);

  }

  componentDidMount(){
    let REF = this;

    if(typeof this.props.id !== "undefined"){
      this.setState({ id: this.props.id });
      
    } 

    this.setUser(sessionGet('email')).then(u => REF.sessionCheckAdmin());

  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.id !== this.state.id){
      this.get();
    } 

    if(typeof prevState.user !== "undefined" && this.state.user !== null && prevState.user.id !== this.state.user.id){
      this.setState({ user_id: this.state.user.id });
    }

  }

  render(){

    let REF = this;

    return (

      <>
      <InfoTile></InfoTile>
      <Header></Header>

      <Container>
    <h2>{typeof this.state.id === "undefined" ? "Nova" : "Editar"} Destino <span className="badge badge-info">{typeof this.state.id === "undefined" ? "" : this.state.id }</span></h2>

   <Row>
     
        <div className="form-group col-md-4">         
          <label htmlFor="f-title required">
            Nome do destino
          </label>
          <input className="form-control" name="title" id="f-title" type="text" value={this.state.title} onChange={this.inputChangeHandler}  />
        </div>

        <div className="col-md-4" hidden={typeof this.state.id === "undefined"}>
        <label htmlFor="required">
           Data do cadastro
          </label>
          <input className="form-control" name="created_at_format" type="text" value={this.state.created_at_format} readOnly/>
        </div>
        </Row>
  
        <Row>
        <div className="form-group col-md-4 col-6">         
          <label>
            Destino ativo
            </label>
            <br/>
            <ul className="list-inline">
              <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="ativo" className="custom-control-input" id="f-ativo-sim" type="radio" value="S" checked={this.state.disabled_at === null || typeof this.state.disabled_at === "undefined"} onChange={this.inputChangeHandler}/>
                  <label className="custom-control-label" htmlFor="f-ativo-sim">
Sim   </label>
                  </div>
                </li>
                <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="ativo" className="custom-control-input" id="f-ativo-nao" type="radio" value="N" checked={typeof this.state.disabled_at !== "undefined"} onChange={this.inputChangeHandler}/>
                  <label className="custom-control-label" htmlFor="f-ativo-nao">
Não    </label>
                  </div>
                </li> 
            </ul>
        </div> 
 
        </Row>   

        <div className="row">   

<div className="col-md-12">
<label htmlFor="f-details required">
    Descrição 
  </label>
  <textarea className="form-control" name="details" id="f-details" onChange={this.inputChangeHandler}value={this.state.details} />
</div>
</div>

<Row className="mt-2">
<Col className="d-flex">

<Button variant="danger" className={`${ typeof this.state.id !== "undefined" ? "d-block" : "d-none" } mr-auto`} onClick={this.confirmRemove} >Remover</Button>
<Button onClick={() => this.submitForm()} className="ml-auto">Salvar</Button>
</Col>

</Row>
      </Container>
      </>
    );
  }

  confirmRemove = () => {
    if(window.confirm("Tem certeza de que deseja remover "+this.state.title+"?")){
        this.delete(); 
    }
  }

  submitForm = () => {
    return typeof this.state.id !== "undefined" ? this.put() : this.post();
  } 

  get = () => {
    let REF = this;
    axios.get(CFG.URL_API+'/destination/'+this.state.id, axiosHeader())
    .then(function(response){

      if(response.data == null){
        REF.setState({ query_success: false });
        return null;
      }

        REF.setState({ ...response.data,       
          query_success: true });
    
      //Perform action based on response
  })
    .catch(function(error){
      resourceInvalidException();
    });
   }

   post = () => {
    let REF = this;

    axios.post(CFG.URL_API+'/destination', this.state, axiosHeader())
    .then(function(response){
      window.alert('Registro salvo');
      window.location.href=CFG.URL_APP+"/admin/destination/form/"+response.data.id;
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

   put = () => {
    let REF = this;

    axios.put(CFG.URL_API+'/destination/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      window.alert('Registro salvo');
      REF.get();
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
   delete(){

    axios.delete(CFG.URL_API+'/destination/'+this.state.id, axiosHeader())
    .then(function(response){
      window.alert('Registro removido');
      window.location.href=CFG.URL_APP+"/admin/destinations";
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
}

export default DestinationForm;
