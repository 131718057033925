import React from 'react';
import axios from 'axios';

import {CFG, axiosHeader} from '../CFG';
import {Button, Alert} from 'react-bootstrap';
import { inputChangeHandler, isValid, nascimentoChangeHandler, inputBlurHandler, cepChangeHandler } from '../CustomForm';

import './Login.scss';
import {usuario_lookup, empresa_lookup} from '../util/inputLookup';

class LoginRecuperar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      email_sent: false,
      autorizado: false,
      login_senha_ok: false,
      user_inexistente: false,
      razao: '',
      passoMax: 3,
      passoMin: 1,
      user: '',
      senha: '',
      empresa: '',
      validator: {},
      passo:1,
      info_passo: { 1: 'Insira login do usuário' }
    };

    this.props.titlebar(this.props.title, this.props.description);
    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);
    this.isValid = isValid.bind(this);
    
  }   

  emailSentSuccess = () => {
    return (
      <Alert key={1} variant={"success"}>
        Enviamos seu login e sua nova senha para {this.state.email_sent}  
    </Alert>
    );
  }

  userNotFound(){
    return (
      <Alert key={1} variant={"warning"}>
    Este usuário não consta em nosso banco de dados
    </Alert>
    );
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.user != this.state.user)
    this.setState({ email_sent: false, user_inexistente: false });
  }

  submitSolicitar = () => {
    let Lr = this;

    this.setState({ loading: true });

    return axios.get(CFG.URL_API+'/user?email='+encodeURI(this.state.user), axiosHeader())
    .then(function(response){
        if(response.data.length > 0){
          Lr.triggerEmail(response.data[0].id);
        }
        else{
          Lr.setState({  email_sent: false, user_inexistente: true });
          Lr.setState({ loading: false });
        }
      //Perform action based on response

    })
    .catch(function(error){
      //Perform action based on error
    });
  }

  triggerEmail = (id) => {
    let Lr = this;
    usuario_lookup(id).then(function(response){
  
      if(response.data != null){
          Lr.setState({ user_inexistente: false });
          Lr.sendEmail(response.data.email);
       
      }

    })

  }

  sendEmail = (email) => {
    let Lr = this;

    return axios.post(CFG.URL_API+'/password-remember', {email}, axiosHeader())
    .then(function(response){
        Lr.setState({ loading:false, email_sent: response.data.email, user_inexistente: false });
      //Perform action based on response
    })
    .catch(function(error){
      //Perform action based on error
    });
  }

  postPasso = () => {
      if(this.state.user != '')
      return this.submitSolicitar();
    }

  render() {
    let Lr = this;
    return (    
      <>
     <div className="page login-page">
  <div className="container d-flex align-items-center">
    <div className="form-holder has-shadow">
      <div className="row">
  
        <div className="col-lg-6 bg-white">
          <div className="form d-flex align-items-center">
            <div className="content h-100 d-flex align-items-center">
           <div className="form-group">
            <h3>Esqueci minha senha</h3>
            <input id="login-username" onKeyPress={Lr.onKeyPressed} placeholder="Usuário" type="text" name="user" required className="input-material form-control pl-2" value={this.state.user} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
            {
            (() => {  
              if(Lr.state.user_inexistente)
              return Lr.userNotFound()

              if(Lr.state.email_sent)
              return Lr.emailSentSuccess();
              })()
          } 
          <Button onClick={this.submitSolicitar} className="mt-2 float-right">Próximo</Button>
          </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="info d-flex align-items-center">
            <div className="content">
              <div className="logo">
                <h1>ANFITRIÃO DE VIAGENS</h1>
              </div>
              <p>{this.state.info_passo[this.state.passo]}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</>
    );
  }

  onKeyPressed = (event) => {

    let code = event.keyCode || event.which;

    if(code == 13 ){
      this.postPasso();
    }  
  }

   componentDidMount(){
    document.addEventListener("keydown", this.onKeyPressed, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }

}
export default LoginRecuperar;