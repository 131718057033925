import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import './NavbarTop.scss';
import DatePicker from 'react-date-picker';
import { inputChangeHandler, isValid, selectChangeHandler } from './CustomForm';

import { Col} from 'react-bootstrap';
import TripLookup from './TripLookup';

class NavbarTop extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
     
            cal_start_at: '',
            cal_end_at: '',
            start_at: '',
            end_at: '',
            validator: {},
            form: {}
     
        };
        
        this.inputChangeHandler = inputChangeHandler.bind(this);
        this.selectChangeHandler = selectChangeHandler.bind(this);
        
        this.isValid = isValid.bind(this);
    }
    
    start_atChangeHandler = (date) => {

        if(date == null){
          this.setState({ cal_start_at: null, start_at: '' });
          return;
        }

        let REF = this;
        let dt = date;
        let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1)+'-'+dt.getDate().toString().padStart(2, '0');
        this.setState({ cal_start_at: dt, start_at: dt_format }); 
        setTimeout(() => REF.formControl(REF.state), 300);
      }
    
    end_atChangeHandler = (date) => {

        if(date == null){
          this.setState({ cal_end_at: null, end_at: '' });
          return;
        }

        let REF = this;
        let dt = date;
        let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1)+'-'+dt.getDate().toString().padStart(2, '0');
        this.setState({ cal_end_at: dt, end_at: dt_format }); 

        setTimeout(() => REF.formControl(REF.state), 300);
      }

    formControl = (form) => {
        let f = Object.assign(this.state.form, form);
        this.setState({ form: f });
        this.props.formcontrol(f);
    }

    onChange = (pair, actionMeta) => {
        this.selectChangeHandler(pair, actionMeta);
    }

    render() {
        return (
            <section className="NavbarTop">
                <Container className="p-0 p-md-2 mt-4 mt-md-0">
                    <Navbar collapseOnSelect expand="lg">
                   
                        <Nav className="row w-100" style={{ display: 'contents' }}>
                          
                            <Col md="8" xs="12" >
                            <TripLookup formcontrol={this.formControl} change={this.onChange} />
                            </Col>
                            <Col md="2" xs="6" className="cj my-3 my-md-0">
                                <label>de</label>
                                <DatePicker autoComplete="off" name="col_start_at" id="f-start_at" value={this.state.cal_start_at} onChange={this.start_atChangeHandler} />
                            </Col>
                                <Col md="2" xs="6" className="cj my-3 my-md-0">
                                <label>até</label>
                                <DatePicker autoComplete="off" name="col_end_at" id="f-end_at" value={this.state.cal_end_at} onChange={this.end_atChangeHandler} />
                            </Col>
                            </Nav>
                        
                    </Navbar>
                </Container>
            </section>
        );
    }
}
export default NavbarTop;
