import React from 'react';
import './App.css';
import './bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import news_1 from './img/news/balanco_1.png';
import news_2 from './img/news/alterosa_1.png';
import './News.scss'; 

class News extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            form: {},
            news:[]     
        };
    
    }

  render(){

    return (
            <>
              <Container className="News">
              <Row>
                  <Col md="12">
                    <h3 className="text-center">TRIP é notícia</h3>
                  </Col>
              </Row>
                <Row>
                <Col className="d-none d-md-block"></Col>
                  <Col>
                    <a href="https://noticias.r7.com/minas-gerais/mg-no-ar/videos/ferias-balanco-geral-desembarca-em-cabo-frio-mg-com-turma-de-mineiros-02012017" target="_blank">
                      <img src={news_1} className="img-fluid img-thumbnail" />
                    </a>
                  </Col>
                  <Col>
                    <a href="https://www.youtube.com/watch?v=rrTEId2R6iM" target="_blank">
                      <img src={news_2} className="img-fluid img-thumbnail" />
                    </a>
                  </Col>
                  <Col  className="d-none d-md-block"></Col>
                </Row>
              </Container>
            </>
    );
  }

}

export default News;
