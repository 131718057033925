
import {sessionGet} from './usuario/Auth';

const ENV = window.location.origin.includes("trip.matteus.dev") ? "production" : "local";

export const CFG = (() => {

  if(ENV == 'production')
  return {
    SESSION_PREFIX: 'trip',
    IGNORE_AUTH : ['login', 'login-recuperar'],
    URL_APP : 'https://trip.matteus.dev',
    URL_API : 'https://trip.matteus.dev/api/public'
  };

  if(ENV == 'local')
  return {
    SESSION_PREFIX: 'trip',
    IGNORE_AUTH : ['login', 'login-recuperar'],
    URL_APP : 'http://192.168.4.147:3000',
    URL_API : 'http://192.168.4.147:8000'
  };

})();

export function axiosHeader(){
  return  {
    headers: {
      'Authorization': 'Bearer '+sessionGet('key') }
  }
}

export function qrCodeHash(id){

}

export function qrCodeHashDecode(hash){
    
}

export const qrCodeValidate = CFG.URL_APP+'/admin/charges/form';

export default CFG;