import React from 'react';
import pathMap from '../util/pathMap';
import LayoutWide from '../layout/LayoutWide';

function map(props){
    return {
            "pages" : {
                "login" : LayoutWide,
                "login-recuperar" : LayoutWide,
            }
        };
}

export function layoutMap(){

    //use o segundo ídice
    let idx = pathMap()[1];

    return map().pages[idx];
}

export default layoutMap;