import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from './CFG';
import { Container, Card, Button, Alert } from 'react-bootstrap';
import './Trips.scss';

import {Row, Col} from 'react-bootstrap';
import ChargeModal from './ChargeModal';

class Trips extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            showChargeModal: false,
            validator: {},
            id: 0,
            list: [],
            restartForm: 1,
            selectedItem: undefined     
        };
        
    }

    componentDidUpdate(prevProps){

        if(this.state.id != this.props.id
            ||   prevProps.start_at != this.props.start_at
            ||   prevProps.end_at != this.props.end_at
            ){
                let REF = this;
            this.setState({ id: this.props.id });
            setTimeout(() => REF.get(), 1000);
        }

    }

    displayChargeModal = () => {
        return (
            <ChargeModal reset={this.state.restartForm} className="ChargeModal" trip={this.state.list[this.state.selectedItem]} show={this.state.showChargeModal} onHide={this.toggleChargeModal}></ChargeModal>)
    }

    toggleChargeModal = (showChargeModal) => {
        this.setState({ showChargeModal, restartForm: (showChargeModal ? 0 : 1) });
    }

    noTripsInfo = () => {
        if(this.state.list.length == 0){
            return (<Alert variant="info">
                Faça uma busca
            </Alert>);
        }
    }

    render() {

        return (
            <section className="Trips">
                <Container className="p-0 p-md-2">
                    {this.displayChargeModal()}
                    {this.noTripsInfo()}
                    {
                        this.state.list.map((e, i) => {
                            return (
                            <Card className="my-2" key={i}>
                            <Card.Body className="p-0">
                                <Row className="py-3 m-0">
                                    <Col md="2" className="d-flex">
                                        <Button onClick={() => this.setState({ showChargeModal: true, selectedItem: i })}  variant="success" className="m-auto">
                                            Comprar<br/>
                                            Agora
                                        </Button>
                                    </Col>
                                    <Col md="10">
                                        <h4>{e.title}</h4>
                                        <p>{e.details}</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                            <Row>
                                    <Col hidden={typeof e.charge_max === "undefined"}>
                                    Lotação: {e.charge_max}
                                    </Col>
                                    <Col>
                                    Partida: {e.end_at_format}
                                    </Col>
                                    <Col>
                                    Volta: {e.end_at_format}
                                    </Col>
                                </Row>
                            </Card.Footer>
                            </Card>
                        )}
                        )
                    }
                </Container>
            </section>
        );
    }

    get = () => {
        let REF = this; 

        let url = CFG.URL_API+'/trip?id='+this.state.id;
        
        if(typeof this.props.start_at !== "undefined")
        url += '&start_at='+this.props.start_at;
        
        if(typeof this.props.end_at !== "undefined")
        url += '&end_at='+this.props.end_at;
        
      return axios.get(url, axiosHeader())
        .then(function(response){

            let list = response.data.filter((t) => {
                return t.charge_count <= t.charge_max;
            });

            REF.setState({ list, loading: false });

      })
        .catch(function(error){
            console.log(error);
      //requestException(error);
 });
    }
}
export default Trips;
