import React from 'react';
import { inputChangeHandler, inputBlurHandler } from './CustomForm';

class EstadoSelect extends React.Component{ 

    constructor(props){
        super(props);

        this.state = {
            uf: this.props.uf,
            validator: {}
        };
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.inputBlurHandler = inputBlurHandler.bind(this);
      }

    inputChangeHandler(event){
        if(typeof this.props.change === "function")
        this.props.change(event);
      }

    componentDidUpdate(prevProps, prevState){
     if(prevState.uf != this.props.uf){
         this.setState({ uf: this.props.uf });
     }
    }

    render(){
    return (
        <select defaultValue="AC" readOnly={this.props.readOnly} className="form-control" id="uf" name="uf" value={this.state.uf} onChange={this.inputChangeHandler}  >
            <option></option>
            <option value="AC">Acre</option>
            <option value="AL">Alagoas</option>
            <option value="AP">Amapá</option>
            <option value="AM">Amazonas</option>
            <option value="BA">Bahia</option>
            <option value="CE">Ceará</option>
            <option value="DF">Distrito Federal</option>
            <option value="ES">Espírito Santo</option>
            <option value="GO">Goiás</option>
            <option value="MA">Maranhão</option>
            <option value="MT">Mato Grosso</option>
            <option value="MS">Mato Grosso do Sul</option>
            <option value="MG">Minas Gerais</option>
            <option value="PA">Pará</option>
            <option value="PB">Paraíba</option>
            <option value="PR">Paraná</option>
            <option value="PE">Pernambuco</option>
            <option value="PI">Piauí</option>
            <option value="RJ">Rio de Janeiro</option>
            <option value="RN">Rio Grande do Norte</option>
            <option value="RS">Rio Grande do Sul</option>
            <option value="RO">Rondônia</option>
            <option value="RR">Roraima</option>
            <option value="SC">Santa Catarina</option>
            <option value="SP">São Paulo</option>
            <option value="SE">Sergipe</option>
            <option value="TO">Tocantins</option>
            <option value="EX">Estrangeiro</option>
        </select>
    );
    }
}

export default EstadoSelect;