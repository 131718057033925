import React from 'react';
import { Carousel, Button, ButtonGroup } from 'react-bootstrap';
import './BannerSlide.scss';
import banner_vale_viagem from './img/banner_vale_viagem.png';
import promo_1 from './img/praias.jpg';
import promo_2 from './img/cachoeiras.jpg';
import promo_3 from './img/internacionais.jpg';
import CFG from './CFG';
function NavbarTop() {
    return (
        <section className="BannerSlide">
            <Carousel slide={true}>

            <Carousel.Item>
                <a target="_blank" href={CFG.URL_APP+"/formulario-sorteio"} className="py-3">
                    <img src={banner_vale_viagem} alt="SORTEIO" className="d-block img-fluid" />
                    </a>
                </Carousel.Item>

            <Carousel.Item>
            <a href={CFG.URL_APP+"/search"} className="py-3">
                <img src={promo_1} alt="" className="d-block img-fluid" />
                </a>
            </Carousel.Item>
                <Carousel.Item>
                <a href={CFG.URL_APP+"/search"} className="py-3">
                    <img src={promo_2} alt="" className="d-block img-fluid" />
                    </a>
                </Carousel.Item>

                <Carousel.Item>
                <a href={CFG.URL_APP+"/search"} className="py-3">
                    <img src={promo_3} alt="PADRÃO CEMIG - 1 CAIXA - 110V - DISJUNTOR 40 AMPERES - 4,5 METROS COMPLETO POR R$350 EM 3X NO CARTÃO" className="d-block img-fluid" />
                    </a>
                </Carousel.Item>
   
            </Carousel>
        </section>
    );
}
export default NavbarTop;
