import React from 'react';
import '../bootstrap.min.css';

import InfoTile from '../InfoTile';
import Header from '../Header';
import {Row,Col, Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { sessionGet, AuthCheck, setUser } from '../usuario/Auth';
import { accessForbiddenException } from '../util/exception' ;

class Admin extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {

          auth: sessionGet('key'),
     
            form: {}
     
        };

        this.setUser = setUser.bind(this);
    
    }
    
    componentDidMount(){

      if(!AuthCheck()){
          accessForbiddenException();
      }

      this.setUser(sessionGet('email'));
    }
    formControl = (formRef) => {
      delete formRef.form;
      delete formRef.list;
      this.setState({ form: formRef });
    }
    
  render(){

    return (
            <>
                 <InfoTile></InfoTile>
               <Header></Header>
        
               <Container>
                 <h4>Admin</h4>
            <Row>
            <Col className=" m-2 p-0">
                    <Link to="/admin/trips" className="p-4 btn btn-success m-auto d-block">
                        Viagens
                    </Link>
                </Col>
                <Col className=" m-2 p-0">
                    <Link to="/admin/charges" className="p-4 btn btn-primary m-auto  d-block">
                        Reservas
                    </Link>
                </Col>

                <Col className=" m-2 p-0" >
                    <Link to="/admin/destinations" className="p-4 btn btn-secondary m-auto d-block">
                        Destinos
                    </Link>
                </Col>

            </Row>
            <Row>
   
            </Row>
            </Container>
            </>
    );
  }

}

export default Admin;
