import React from 'react';
import './App.css';
import './Landing.scss';
import './bootstrap.min.css';
import InfoTile from './InfoTile';
import Header from './Header';
import BannerSlide from './BannerSlide';
import News from './News';
import CFG from './CFG';
import {Link} from 'react-router-dom';

class Landing extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
     
            form: {}
     
        };
    
    }
    
    formControl = (formRef) => {
      delete formRef.form;
      delete formRef.list;
      this.setState({ form: formRef });
    }
    
  render(){

    return (
            <>
               <InfoTile></InfoTile>
               <Header></Header>
               <BannerSlide></BannerSlide>
               <div className="w-100 text-center p-4">
                <Link to="/formulario-sorteio" class="btn btn-success btn-lg d-block" id="btn-call-to-draw">Participar do Sorteio</Link>
               </div>
               
               <div className="my-4 py-4 text-center">
                 <a href={CFG.URL_APP+"/avaliacao"}><img src="/img/5stars.png" className="img-fluid m-auto d-block" /></a>
               </div>

            </>
    );
  }

}

export default Landing;
