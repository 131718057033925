import React from 'react';
import TestimonialModal from './TestimonialModal';  
import {Container, Row, Col} from 'react-bootstrap';
import InfoTile from './InfoTile';
import axios from 'axios';
import Header from './Header';  
import {FormControl, Button, Alert} from 'react-bootstrap';  
import DatePicker from 'react-date-picker';
import {axiosHeader, CFG} from './CFG';
import Select from 'react-select';
import {mask_decimal, parseDecimal} from './util/inputMask';

import { inputChangeHandler, isValid } from './CustomForm';

class FormDraw extends React.Component   {
	
	constructor(props){
		super(props);

	this.state = {
            name: '',
            trip_date: '',
            birth_date: '',
            destination: '',
            pay_value: 0,
            cal_trip_date: '',
            cal_birth_date: '',
            sent: false,
            validator: {},
            loading: false,
            key: null,
            existent: false,
            destinations_available : [
                'Cabo frio ',
                'Porto Seguro ',
                'Caldas Novas ',
                'Arraial do Cabo ',
                'Aparecida ',
                'Búzios ',
                'Brás - SP  ',
                'Olímpia - sp',
                'Capitólio '
            ]
        };
        this.isValid = isValid.bind(this);

            this.inputChangeHandler = inputChangeHandler.bind(this);
        }
        
    birth_dateChangeHandler = (date) => {

        if(date == null){
          this.setState({ cal_birth_date: null, birth_date: '' });
          return;
        }

        let REF = this;
        let dt = date;
        let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1)+'-'+dt.getDate().toString().padStart(2, '0');
        this.setState({ cal_birth_date: dt, birth_date: dt_format }); 
       
      }

    trip_dateChangeHandler = (date) => {

        if(date == null){
          this.setState({ cal_trip_date: null, trip_date: '' });
          return;
        }

        let REF = this;
        let dt = date;
        let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1)+'-'+dt.getDate().toString().padStart(2, '0');
        this.setState({ cal_trip_date: dt, trip_date: dt_format }); 
       
      }
      submitForm = () => {
        let REF = this;
 
        let post_data = this.state;
        post_data.pay_value = post_data.pay_value.replace(/\,/i, '');
        REF.setState({ loading: true });
        axios.post(CFG.URL_API+'/participant', post_data, axiosHeader())
        .then(function(response){
                if(typeof response.data.key === "undefined"){
                        window.alert('Parece que esse número já foi cadastrado!');
                        REF.setState({ loading: false, existent: true });
                } else {
                        REF.setState({ loading: false, sent: true, key: response.data.key, existent: false });
                }
          //Perform action based on response
      })
        .catch(function(error){
                REF.setState({ existent: true, loading: false });
                window.alert('Parece que esse número já foi cadastrado!');

          //Perform action based on error
        });
      }

      renderAlert(msg , classname){
              return (
                <>
      
                   <Row className="my-4">
                       <Col>
                       <Alert className={`alert-${classname}`}>
                        {msg}
                       </Alert>
                       </Col>
                       </Row>
                       </>
              )
      }
	render(){

        let REF = this;

        if(this.state.loading){
               return this.renderAlert('Estamos enviando os dados', 'warning')
        }

        if(this.state.existent){
                return this.renderAlert('O cadastro desse telefone já foi realizado', 'warning')
        }

        if(this.state.sent){

                return (
                        <>
                        <InfoTile></InfoTile>
                        <Header></Header>
                           <Container>
         
                           <Row  className="my-4">
                               <Col>
                               <h1>Sorteios ocorrem na Live todo dia 10.Fique ligado!</h1>
                               {this.renderAlert(' Sua chave comprovante é '+this.state.key, 'success')}
                        
                               <Alert className="alert-success">
                                         Recebemos seu cadastro. Aguarde notícias e instruções.
                               </Alert>
                               </Col>
                               </Row>
                               </Container>
                               </>
                )
        }

  return (
			<>
      
                <InfoTile></InfoTile>
               <Header></Header>
                  <Container>

                  <Row>
                      <Col>
                      <h1 className="text-center my-3">Preencha os seguintes dados para participar (CADASTRO INDIVIDUAL):</h1>
                    
                      </Col>
              </Row>

              <Row>
                      <Col>
                      <p className="text-center">Dados falsos invalidarão o cadastro. Participe com responsabilidade.</p>
                    
                      </Col>
              </Row>

              <Row>
                      <Col md="6">
                      <label for="f-name">Nome COMPLETO</label>
                      <FormControl name="name" value={this.state.name} onChange={this.inputChangeHandler}></FormControl>
                    
                      </Col>

                      <Col md="6">
                      <label for="f-name">Telefone (será sua chave)</label>
                      <FormControl name="phone" value={this.state.phone} onChange={this.inputChangeHandler}></FormControl>
                    
                      </Col>
              </Row>

              <Row className="my-3">
                                   
              <Col md="6">
                      <label for="f-name">Destino</label>
                      <select className="form-control" name="destination" value={this.state.destination} onChange={this.inputChangeHandler}>
                        {
                        REF.state.destinations_available.map((d, k) => {
                                             console.log(d);
                                             return (<option value={k}>{d}</option>);
                                   
                             })
                        }
                      </select>
                    
                      </Col>

                      <Col  md="6">
                        <label for="f-trip-date">Data de nascimento</label><br/>
                        <DatePicker className="form-control" autoComplete="off" name="cal_birth_date" id="f-birth-date" value={this.state.cal_birth_date} onChange={this.birth_dateChangeHandler} />

                      </Col>
              </Row>

              <Row>

              <Col  md="6">
                        <label for="f-trip-date">Data da viagem</label><br/>
                        <DatePicker className="form-control" autoComplete="off" name="cal_trip_date" id="f-trip-date" value={this.state.cal_trip_date} onChange={this.trip_dateChangeHandler} />

                      </Col>

                      <Col md="6">
                      <label for="f-name">Valor que pagou no pacote (R$)</label>
                      <FormControl name="pay_value" value={mask_decimal(this.state.pay_value)} onChange={this.inputChangeHandler}></FormControl>
                    
                      </Col>
                </Row>

              <Row className="my-3 text-right">
                      <Col>
                      <Button onClick={this.submitForm} type="success" size="lg">Enviar</Button>
                    
                      </Col>
              </Row>

              <Row className="my-3 text-right">
                      <Col> 
                      <h1>Regulamento</h1>
                      Prêmio mensal de R$500,00 vale viagem,<strong>para quem comprou e viajou pela agência TRIP </strong>no mês anterior ao sorteio e fizer o cadastro no link da promoção. 

<p>Não é válido para quem compra em outras agências.</p>

<p> O sorteio é exclusivo para quem compra com @trip ou com um de seus agentes de viagens autorizados. </p>

<p>Nós meses julho/21 e janeiro/22 tem sorteio extra para quem viajou durante os meses anteriores com nossa empresa. </p>
<p>Vale viagem de R$1000,00. </p>

<p>O crédito pode ser usado em até seis meses após o sorteio. Em viagem de execução da @transbaixinho</p>

<p>Em janeiro do ano seguinte, todos que viajou no Ano da promoção vai concorre a um sorteio extra uma TV 50. </p>

<p>O cadastro é válido durante o ano da viagem. </p>

<p>Virou ano, zera tudo.</p>

<p>Quem viajar mais de uma viagem terá mais chance pq o cadastro e por viagem, quando mais viajar mais chance de ganhar. </p>

<p>O prêmio não pode ser trocado por dinheiro. </p>

<p>É não pode ser transferido o prêmio.</p>

<p>Quanto mais viajar mais chance de ganhar.</p>
                      </Col>
                </Row>
            
                </Container>
			</>
  );
	}
}

export default FormDraw;