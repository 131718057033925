import React from 'react';
import './Charges.scss';
import './bootstrap.min.css';

import InfoTile from './InfoTile';
import Header from './Header';
import axios from 'axios';
import {CFG, axiosHeader, qrCodeValidate} from './CFG';
import {sessionGet, setUser} from './usuario/Auth';
import {Col, Row, Card, Container} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {mask_decimal} from './util/inputMask';
var QRCode = require('qrcode.react');

class Charges extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
          user: {
            id:0
          },
            list: [],     
            form: {}     
        };

        this.setUser = setUser.bind(this);
    
    }

    componentDidMount(){
      let email = sessionGet('email');
      this.setUser(email);
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.user.id != this.state.user.id){
      this.get();
    }
  }

    formControl = (formRef) => {
      delete formRef.form;
      delete formRef.list;
      this.setState({ form: formRef });
    }

    get = () => {
      let REF = this; 

      let url = CFG.URL_API+'/charge?user_id='+this.state.user.id;
      
    return axios.get(url, axiosHeader())
      .then(function(response){
          REF.setState({ list: response.data, loading: false });
      })
      .catch(function(error){
          console.log(error);
    //requestException(error);
});
  }
    
  render(){

    return (
            <>
                <InfoTile></InfoTile>
               <Header></Header>
        
               <Container>
               <h3 className="text-uppercase"><i className="fa fa-list"></i> Minhas reservas</h3>
               <div className="`alert alert-info ${this.state.list.length == 0 ? 'visible' : 'invisible'}`">Não há reservas até o momento.<br/> Mas não se preocupe. Ainda dá tempo de conhecer nossos pacotes e faça suas reservas!<br/>
               <Link to="/search" className="btn btn-primary">Pesquisar pacotes</Link></div>
               {
                        this.state.list.map((e, i) => {

                            return (
                            <Card className="my-4 my-md-2">
                            <Card.Body>

<Row>
              <Col md="3">
                <QRCode value={qrCodeValidate+'/'+this.state.id} className="d-block m-auto my-4" size={150} fgColor={"black"} />
                <span className="badge badge-info float-right">#{e.id}</span>
                </Col>
                <Col md="9">
                <Row>
                                      <Col>
                            <h5>{e.title} </h5>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                      {e.details}
                                      </Col>
                                    </Row>
                                    <Row className="mt-2">
                                      <Col md="8">
                                      PagSeguro: <span className="badge badge-success">{e.status ? e.status.title : null}</span>
                                        <small className="text-muted ml-2">{e.status ? e.status.details : null}</small>
                                      </Col>
                                    <Col md="4">
                                      Valor: R${mask_decimal(this.state.amount)}                                      
                                      </Col>
                                    </Row>
                                      <Row className="mt-2">
                                      <Col md="4">
                                      Compra: <u>{e.created_at_format}</u>
                                      </Col>
                                      <Col md="4">
                                      Saída: <u>{e.start_at_format}</u>
                                      </Col>
                                      <Col md="4">
                                      Volta: <u>{e.end_at_format}</u>
                                      </Col>
                                    </Row>
                </Col>
              </Row>
                              
                            </Card.Body>
                      
                            </Card>
                        )}
                        )
                    }
                    </Container>
            </>
    );
  }

}

export default Charges;
