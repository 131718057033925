import React from 'react';
import axios from 'axios';
import { axiosHeader, CFG } from '../CFG';

export function AuthCheck(){
    if(sessionGet('key') == undefined)
    return false;
    let [email, password] = atob(sessionGet('key')).split("||");
    let Lg = this;
    return axios.post(CFG.URL_API+'/usuario/check', { 
        email, password
     }, axiosHeader())
    .then(function(response){
       // updateSessao();
        return response.data.success;
    })
    .catch(function(error){
      //Perform action based on error
      return false;
    });
}

export function sessionRemove(keys){
    if(Array.isArray(keys)){
        for(let key of keys){
            sessionStorage.removeItem(CFG.SESSION_PREFIX+'.'+key);
        }
    } else {
        sessionStorage.removeItem(CFG.SESSION_PREFIX+'.'+keys);
    }
}

export function sessionGet(key){
    return sessionStorage.getItem(CFG.SESSION_PREFIX+'.'+key);
}

export function sessionSet(key,value){
    sessionStorage.setItem(CFG.SESSION_PREFIX+'.'+key, value);
}

export function generateKey(email, password){
    return btoa(email+"||"+password);
}

export function updateUsuario(email, password){
    sessionSet('email', email);
    sessionSet('key', generateKey(email, password));
}

export function startSessao(email, password, location){

    updateUsuario(email, password);

    sessionSet('last_activity', Date.now());

    if(typeof location !== "undefined")
    setTimeout(() => window.location.href=location, 500);
}

export function updateSessao(){

let diff = Math.abs(new Date() - sessionGet('last_activity'));

sessionSet('last_activity', Date.now());

}

export function removeAllSessionKeys(){
    sessionRemove(['key','email', 'last_activity']);
}

export function logout(msg = false){
        removeAllSessionKeys();
        if(msg)
        window.alert('Sessão expirada. Efetue o login novamente.');
        window.location.href=CFG.URL_APP+'/login';
}

export function setUser(email){
        let REF = this;
    return axios.get(CFG.URL_API+'/session-check?email='+email, axiosHeader()).then(r => {

        if(r.data === null)
        return null;

        REF.setState({ user: r.data });

        return r.data;
    });
}

export function sessionCheckAdmin(){
    if(typeof this.state.user === "undefined" || this.state.user == null || parseInt(this.state.user.role_id) !== 10){
        removeAllSessionKeys();
        window.alert('Você não possui privilégios de administrador');
        window.location.href=CFG.URL_APP+"/login";
    }
}

export function logoutConfirm(){
    if(window.confirm("Tem certeza de que deseja sair?")){
        removeAllSessionKeys();
        window.localStorage.setItem('logout_confirm', true);
        window.location.href=CFG.URL_APP+'/login';  
      }
}

export function isAdmin(){
    let email = sessionGet('email');

    if(email === null)
    return false;
    
    this.setUser(email);

    let REF = this;

   setTimeout(() => {
        REF.setState({ is_admin: REF.state.user.role_id === 10 })
    }, 2000);

}

export default AuthCheck;