import React from 'react';
import axios from 'axios';
import CFG from '../CFG';

export function validate_senha_decode(input){
  
  var re = /^([\d]{4})$/

	return re.test(input.value);
}

export function validate_room(input){
  return validate_decimal(input);
}

export function validate_age(input){
  return validate_decimal(input);
}

export function validate_boarding(input){
  return validate_decimal(input);
}

export function validate_qtd(input){
  return validate_integer(input);
}

export function validate_prvenda(input){
  return validate_decimal(input);
}

export function validate_cofins(input){
  return validate_decimal(input);
}
export function validate_custo(input){
  return validate_decimal(input);
}

export function validate_icms(input){
  return validate_decimal(input);
}
export function validate_icms_st(input){
  return validate_decimal(input);
}
export function validate_ipi(input){
  return validate_decimal(input);
}
export function validate_mva(input){
  return validate_decimal(input);
}
export function validate_pis(input){
  return validate_decimal(input);
}
export function validate_preco(input){
  return validate_decimal(input);
}
export function validate_amount(input){
  return validate_decimal(input);
}
export function validate_tara(input){
  return validate_decimal(input);
}

export function validate_limite_d1(input){
  return validate_decimal(input);
}

export function validate_limite_d2(input){
  return validate_decimal(input);
}
export function validate_limite_d3(input){
  return validate_decimal(input);
}
export function validate_limite_d4(input){
  return validate_decimal(input);
}
export function validate_limite_d5(input){
  return validate_decimal(input);
}
export function validate_limite_d6(input){
  return validate_decimal(input);
}
export function validate_valor(input){
  return validate_decimal(input);
}

export function validate_decimal(input){

  if(typeof input == "undefined" || input == null)
  return true;

  if(input.value === 0)
  return true;

  let valor = '';

  if(typeof input.value !== "undefined"){
    valor = input.value;
  } else {
    valor = input;
  }

  if(valor.length <= 2){
    return true;
  }

  var re = /(\d{1,3}\.)*([\d]{2,3}\,)*(\d{2})/;

return re.test(valor);
}

export function validate_cc_number(input){

  var re = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})|((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})|(606282\d{10}(\d{3})?)|(3841\d{15})$/;

return re.test(input.value);
}

export function validate_integer(input){

    var re = /^[\d]*$/;

  return re.test(input.value);
}

export function validate_cc_birthdate(input){
  return validate_nascimento(input);
}

export function validate_nascimento(input){

  let splitted = input.value.split('/');
  let [d,m,y] = splitted;

  if(d > 31 || m > 12 || y > new Date().getFullYear())
  return false;

  var re = /^(\d{2})[\-\/]{1}(\d{2})[\-\/]{1}(\d{4})$/; // Pode usar ? no lugar do *
	if(re.test(input.value)){
		return input.value.replace(re,"$1/$2/$3");
	}
	return false;
}

export function validate_celular(input){

  var re = /^([\d]{4,5})-([\d]{4})$/
  return re.test(input.value);
}

export function validate_telefone(input){

  var re = /^([\d]{4,5})-([\d]{4})$/

	return re.test(input.value);
}

export function validate_cc_name(input){

  return validate_name(input);

}

export function validate_name(input){
  
  var re = /^(\w|\s){10,255}$/

	return re.test(input.value);
}

export function validate_codbarra(input){

  var re = /^(\s|[0-9]|\-)+$/

	return re.test(input.value);
}

export function validate_phone(input){
  return validate_celular(input);
  
  }

export function validate_cc_phone(input){
return validate_celular(input);

}

export function validate_cc_cvv(input){

  var re = /^(\d{1,3})$/;

  return re.test(input.value);

}

export function validate_cc_email(input){
  return validate_email(input);
}

export function validate_email(input){
  var re = /\S+@\S+\.\S+/;
  return re.test(input.value);
}

export function validate_cep(input){
  var re = /^([\d]{2})\.?([\d]{3})\-?([\d]{3})/;
  let v = input.value.toString().match(/\d+/g);
  if(v != null && v.join('').length == 8)
  return v.join('').replace(re,"$1.$2-$3");
  return false;
}

export function validate_cnpj(input){
  let re =  /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/;
  return re.test(input.value);
}

export function validate_cpf(input){
  if(input.value.length != 14){
    return false;
  }
 /* const cpfRegex = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/
  if(cpfRegex.test(input.value) == true)
  return true; */
  let cpf = input.value;
		cpf = input.value.split("");
		cpf.splice(3, 1);
		cpf.splice(6, 1);
		cpf.splice(9, 1);
		var digitoVerificador = 0;
		let soma = ((cpf[0] * 11) + (cpf[1] * 10) + (cpf[2] * 9) + (cpf[3] * 8) + (cpf[4] * 7) + (cpf[5] * 6) + (cpf[6] * 5) + (cpf[7] * 4) + (cpf[8] * 3) + (cpf[9] * 2) + (cpf[10] * 0));
		let resto = ((soma * 10) % 11);
		if (resto <= 9) {
			if (cpf[10] == resto) {

        let db_check = cpf_lookup(input);
        return db_check.then((kp) => (kp == null));
       
 			} else {
				return false;
			}
		}
}

export function cpf_lookup(input){
 return axios.get(CFG.URL_API+'/responsavel?selectMode=pluck&pluckKey=id&pluckValue=nome&cpf='+input.value+'&id='+input.getAttribute('data-id'))
  .then(function(response){
    return response.data;
    //Perform action based on response
  })
  .catch(function(error){
    //Perform action based on error
  });
 }

 export function cnpj_lookup(){
  return axios.get(CFG.URL_API+'/empresa?selectMode=pluck&pluckKey=id&pluckValue=nome&cnpj='+encodeURIComponent(this.state.cnpj))
   .then(function(response){
     return response.data;
     //Perform action based on response
   })
   .catch(function(error){
     //Perform action based on error
   });
  }

  export function validate_horario_de(input){
    return validate_horario(input);
  }
  export function validate_horario_ate(input){
    return validate_horario(input);
  }

export function validate_datetime(dateTimeString) {
  var regEx = /^\d\d\d\d-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01]) (00|[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])$/;
  if(!dateTimeString.match(regEx)) return false;  // Invalid format

  var d = new Date(dateTimeString);
  var dNum = d.getTime();
  if(!dNum && dNum !== 0) return false; // NaN value, Invalid date

  return true;
}

  export function validate_horario(input){
    var re = /^(:|\d){1,4}$/;
    let valor = input.value;
  valor = valor.padStart(4, '0');
  valor = valor.substring((valor.length)-4);
    return re.test(valor);
  }

  export function validate_hsaida(input){
    return validate_horario(input);
  }

  export function validate_cidade(input){
    return validate_required(input);
  }

  export function validate_bairro(input){
    return validate_required(input);
  }

  export function validate_numero(input){
    return validate_required(input);
  }

  export function validate_logradouro(input){
    return validate_required(input);
  }

  export function validate_required(input){
    var re = /.+/g;
    return re.test(input.value);
  }

export function inputValidate(event){

  try {
  let validate = true;

  let validateInput = eval("validate_"+event.target.name);

  if(typeof validateInput !== "undefined")
  validate = eval("validate_"+event.target.name)(event.target);

  if(validate == null || event.target.value.length == 0 || event.target.type == 'radio'){
    event.target.classList.remove('is-invalid');
    event.target.classList.remove('is-valid');
    return true;
  }
  
  if(validate){
    event.target.classList.add('is-valid');
    event.target.classList.remove('is-invalid');
  } 
  if(!validate){
    event.target.classList.add('is-invalid');
    event.target.classList.remove('is-valid');
  }
  return validate;
} catch(e){
  if (e instanceof ReferenceError) {
    event.target.classList.remove('is-invalid');
    event.target.classList.remove('is-valid');
    return true;
  }
}

}
export default inputValidate;