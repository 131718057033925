import React, { useRef } from 'react'; // Import React
import './DataTable.scss';
import axios from 'axios';
import {requestException} from './util/exception';
import {CFG, axiosHeader} from './CFG';
import {Row, Col, Alert} from 'react-bootstrap';
// es6
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import { Dropdown} from 'react-bootstrap';

import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ReactToPrint from 'react-to-print';
import {todayDmy} from './util/date';
import LoadingPlaceholder from './util/LoadingPlaceholder';
import icon_print from './img/print.png';
import icon_table from './img/table.png';

export default function e(){
  return true;
}

  export function CustomToggleList({
    columns,
    onColumnToggle,
    toggles
  }) {

    let CTL = this;

    const onToggle = function(isOpen, event, metadata) {

      if(metadata.source == 'keydown')
      alert('keydown');
    
      if(metadata.source == 'select')
      CTL.setState({ popoverVisible : true });

      if(metadata.source == 'click'){
        if(isOpen == false)
        CTL.setState({ popoverVisible : false });
        else
        CTL.setState({ popoverVisible : true });
      }
      
    };  

    return (
      <Dropdown show={CTL.state.popoverVisible} drop={'left'}  onToggle={onToggle}>
      <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="p-0">
      <img src={icon_table} style={{ width: '20px' }} />
      </Dropdown.Toggle>

      <Dropdown.Menu  >

      <Dropdown.Item  
                onSelect={() => CTL.setState({ popoverVisible : false }) }
            eventKey="close"
              >
            Fechar <i className="icon-arrow-right"></i>
                </Dropdown.Item>

      {
          CTL.getColumns()
            .map(column => ({
              ...column,
              toggle: toggles[column.dataField]
            }))
            .map(function(column) {
              if(column.hidden)
              return null;

              return (<Dropdown.Item
                key={column.keyField}
                onClick={ () => onColumnToggle(column.dataField) } 
                href="#"
                eventKey={ column.dataField }
                className={ `list-group-item p-0 ${column.toggle ? '' : 'active'}` }
                aria-pressed={ column.toggle ? 'false' : 'true' }
              >
                <label className="w-column-toggle d-inline-flex m-0 py-2 px-3 w-100 ">
                <input type="checkbox" className="column-toggle mr-1" checked={column.toggle ? 'checked' : ''} defaultValue="1" />
                { column.text }
                </label>
                </Dropdown.Item>
              );
            }
            )
      }
     </Dropdown.Menu>
     </Dropdown>
    );
  };

  export function getListing(uri, filters = {}){
    
        var table = this;
       // table.setState({ data: [] });
        let qs_filters = '';
        var queryString = CFG.URL_API+'/'+uri;
    
        if(filters != {}){
          for (var [key, term] of Object.entries(filters)) {
            if(term != "")
            qs_filters += '&'+key+'={'+term+'}';
          }
  
          if(qs_filters != ''){
            queryString += queryString.indexOf('?') == -1 ? '?' : '&';
            if(queryString.indexOf('whereMode') != -1)
            queryString += qs_filters;
            else
            queryString += 'whereMode=or'+qs_filters;
          }
        }

       return axios.get(queryString, axiosHeader())
        .then(function(response){
          table.setState({ fetchComplete: true, data : response.data });
          //Perform action based on response
        })
        .catch(function(error){
      requestException(error);
 });
      }

   export function printHeader(title) {
        return (
        <div className="print-only print-header">
          <table className="table ">
            <tbody>
            <tr>
              <td>Relatório: {title}</td>
              <td>Data: {todayDmy()}</td>
            </tr>
            </tbody>
          </table>
          </div>);
    }

    export function getCustomToggleList({
      columns,
      onColumnToggle,
      toggles
    }){
      return CustomToggleList({
        columns,
        onColumnToggle,
        toggles
      })
    }

      export function tableRender(resource, sizePerPage){
        let REF = this;
        
        const componentRef = REF;
        
        const rowClasses = (row, rowIndex) => {
          return typeof row.data_removido !== "undefined" && row.data_removido != null ? 'removed' : '';
        };
    
        const selectRow = {
          mode: 'radio',
          hideSelectColumn: true,
          classes: 'active',
          clickToSelect: true
        };
        
        const rowEvents = {
          onClick: (e, row, rowIndex) => {
              window.location.href=CFG.URL_APP+'/'+resource+'/'+row.id;           
          }
        };
          
           const MainSearch = (props) => {
            let input;
            const handleFilterChange = () => {
              let results = props.onSearch(input.value);
    
              this.setState({ searchTerm : input.value  });
    
              let columnsSearch = {};
              for (var entry of this.getColumns()) {
                columnsSearch[entry.dataField] = input.value;
              }
    
              REF.updateListing(columnsSearch);
    
            };
       
            return (
              <input
                placeholder="Filtro: comece a digitar para filtrar"
                onChange={handleFilterChange}
                className="form-control"
                autoFocus
                style={ { backgroundColor: '#fff' } }
                ref={ n => input = n }
                type="text"
                defaultValue={this.state.searchTerm}
                />
              );
            };

            let paginateOrNot;

            if(typeof sizePerPage === "undefined")
            paginateOrNot = paginationFactory({ paginationSize: 5 });
          
            if(Number.isInteger(sizePerPage))
            paginateOrNot = paginationFactory({ paginationSize: sizePerPage });
          
            if(false === sizePerPage)
            paginateOrNot = false;
            
            if(this.state.data.length == 0){
              if(!this.state.fetchComplete){
                return (
                  <LoadingPlaceholder show={true}/>
                );
              } else {
                return (
                  <Alert variant="warning">Nenhum registro a exibir</Alert>
                );
              }
            }
     
            return (
              <div>
       
              <ToolkitProvider
        
              keyField="id"
              data={ this.state.data }
              columns={ this.getColumns() }
              search
              columnToggle
              >
              {
                props => (
                  <div>
                    <Row className="cdt-toolbar">
                        <Col className="col-md-4 offset-md-4 my-1 p-0 w-column-dropdown">
                     
                          <ReactToPrint
                            trigger={() => <button className="btn btn-sm btn-secondary btn-print p-0">
                              <img src={icon_print} style={{ width: '20px' }} />
                              </button>}
                            content={() => componentRef.current}
                          />
                        {REF.getCustomToggleList({...props.columnToggleProps})}
                        </Col>
                        <Col className="col-md-4 my-1">
                            <MainSearch { ...props.searchProps }  />
                        </Col>
                    </Row>
                  <div ref={componentRef}>
                    {printHeader(REF.props.title)}
                    <BootstrapTable
                           rowClasses={ rowClasses }
                           selectRow={ selectRow }
                    { ...props.baseProps }
                    keyField='id' data={ this.state.data } columns={ this.getColumns() } pagination={ paginateOrNot } rowEvents={ rowEvents } 
                    />
                </div>
                  </div>
                  )
                }
                </ToolkitProvider>
                </div>);
      }