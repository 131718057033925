import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../../CFG';
import {sessionGet} from '../../usuario/Auth';
import { inputChangeHandler, isValid, selectChangeHandler } from '../../CustomForm';
import {todayDmy} from '../../util/date';
import {mask_decimal} from '../../util/inputMask';
import InfoTile from '../../InfoTile';
import Header from '../../Header';
import { Container, Row, Col, InputGroup, FormControl } from 'react-bootstrap';

class ChargeForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    user: {},
    item_legend: {
      boarding: 'Embarque', 
      room: 'Hospedagem', 
      age: 'Faixa etária'
    },
      method: {},
      status: {
        name: 'Em aberto'
      },
      cart: '{}',
      trip: {
        id: undefined
      },
      show: false,
      id: undefined,
      empresa_id: sessionGet('empresa'),
      validator: {},
      show: false,
      dt_vencimento: new Date(),
      data_removido_format: undefined,
      dt_entrada: todayDmy(),
      //debito
      tipo: undefined
    };

    this.selectChangeHandler = selectChangeHandler.bind(this);

    this.isValid = isValid.bind(this);

  }

  updateDateIfRemoved = () => {

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      let dt = new Date(this.state.data_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear()+' às '+dt.getHours()+':'+dt.getMinutes(); 
      this.setState({ data_removido_format : dmy })
      this.props.modalcontrol(this);
    }

  }

  componentDidUpdate(prevProps, prevState){

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      this.updateDateIfRemoved();
    }

  }

  componentDidMount(){
    this.get();
  }

  post = () => {

    axios.post(CFG.URL_API+'/charge', this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
     // window.location.reload();
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

   get = () => {
    let REF = this;
    axios.get(CFG.URL_API+'/charge/'+this.props.id, axiosHeader())
    .then(function(response){
      REF.setState({ ...response.data });
      //Perform action based on response
     // window.location.reload();
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

   put = () => {

    axios.put(CFG.URL_API+'/charge/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
 
  })
    .catch(function(error){
      //Perform action based on error
    });

    return true;
   }

   downloadAnexo = () => {
    const a = document.createElement("a");
     // Set the HREF to a Blob representation of the data to be downloaded
  a.href = window.URL.createObjectURL(
    new Blob([this.state.arquivo.conteudo_blob], { type: this.state.arquivo.mime })
  );

  // Use download attribute to set set desired file name
  a.setAttribute("download", this.state.arquivo.nome);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);

   }

   uploadAnexo = (e) => {
    this.inputElement.click();
    }

   render(){
    
    let REF = this;

   return(
     <>
          <InfoTile></InfoTile>
      <Header></Header>
      <Container>
   <h4>Reserva #{this.props.id} <span class="badge badge-info">{this.state.status.name}</span> </h4>
    
      <div className="row">   

      <div className="form-group col-md-4">         
    <label htmlFor="f-charge_status required">
    Status
    </label>
    <input readOnly className="form-control" name="charge_status" id="f-charge_status" type="text" value={this.state.status.title} />
  </div>
<div className="form-group col-md-4">         
    <label htmlFor="f-user_name">
    Titular
    </label>
    <input readOnly className="form-control" name="user_name" id="f-user_name" type="text" value={this.state.user.name} />
  </div>   
  <div className="form-group col-md-4">         
    <label htmlFor="f-trip_title required">
    Viagem
    </label>
    <input readOnly className="form-control" name="trip_title" id="f-trip_title" type="text" value={this.state.trip.title} />
  </div>

  </div>
  <Row>

  <div className="form-group col-md-4">         
    <label htmlFor="f-charge_date">
    Data do registro
    </label>
    <input readOnly className="form-control" name="charge_date" id="f-charge_date" type="text" value={this.state.created_at_format} />
  </div>   
  <div className="form-group col-md-4">         
    <label htmlFor="f-method_title required">
    Método de pagamento
    </label>
    <input readOnly className="form-control" name="method_title" id="f-method_title" type="text" value={this.state.method.title} />
  </div>

  <div className="form-group col-md-4">         
    <label htmlFor="f-transaction_id required">
   Transação no PagSeguro
    </label>
    <input readOnly className="form-control" name="transaction_id" id="f-transaction_id" type="text" value={this.state.transaction_id} />
  </div>
  </Row>
  <Row>
  <div className="form-group col-md-4">         
    <label htmlFor="f-amount required">
   Valor pago
    </label>
    <input readOnly className="form-control" name="amount" id="f-amount" type="text" value={"R$"+mask_decimal(this.state.amount)} />
  </div>
  </Row>
  {this.tripCartList()}
  <Row >
  <div className="form-group col-md-12">         
    <label htmlFor="f-trip_details required">
    Detalhes
    </label>
    <textarea readOnly className="form-control" name="trip_details" id="f-trip_details" value={this.state.details} />
  </div>
  </Row>
  </Container>
    </>
);
   }

   
  tripCartList = () => {

    let REF = this;

    let price_table = Object.entries(JSON.parse(this.state.cart));
    console.log(price_table);

    return price_table.map(([k, list]) => {

      let list_entries = Object.entries(list);

     return (<>
      { list_entries.map(([n, price]) => {

        let amount = '';
        if(typeof REF.state.cart[k] !== "undefined" 
        && typeof REF.state.cart[k][n] !== "undefined" 
        && REF.state.cart[k][n] !== null){
          amount = REF.state.cart[k][n].amount;
        }
       
        return (
          <Row className="item" key={n}>
            <Col md={6} className="d-flex">
              <label className="my-auto"> + {REF.state.item_legend[k]} {price.name}:  </label>
              </Col>
              <Col md={3}>
              <label htmlFor={`f-amount_${k}_${n}`}>
              Valor
            </label>
            <InputGroup className="mb-3">
    <InputGroup.Prepend>
      <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
    </InputGroup.Prepend>
    <FormControl readOnly
     value={amount} name={k} id={`f-amount_${k}_${n}`} type="text" />
  </InputGroup>

              </Col>
         </Row>
        );
      } ) 
    }
     
    </>);
  } );

  }

   delete = () => {

    axios.delete(CFG.URL_API+'/charge/'+this.state.id, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
 
}
export default ChargeForm;