import React from 'react';

function mask_senha_decode(input){
  var re = /^(\d)$/; // Pode usar ? no lugar do *

	return input.value.replace(re,"$1");
	
}

function mask_age(input){
  return mask_decimal(input);
}

function mask_room(input){
  return mask_decimal(input);
}

function mask_boarding(input){
  return mask_decimal(input);
}

function mask_qtd(input){
  return mask_integer(input);
}

function mask_prvenda(input){
  return mask_decimal(input);
}

function mask_cofins(input){
  return mask_decimal(input);
}
function mask_custo(input){
  return mask_decimal(input);
}

function mask_icms(input){
  return mask_decimal(input);
}
function mask_icms_st(input){
  return mask_decimal(input);
}
function mask_ipi(input){
  return mask_decimal(input);
}
function mask_mva(input){
  return mask_decimal(input);
}
function mask_pis(input){
  return mask_decimal(input);
}
function mask_preco(input){
  return mask_decimal(input);
}
function mask_tara(input){
  return mask_decimal(input);
}
function mask_limite_d1(input){

  if(input.value.length > 9)
  return input.value.slice(0,9);
  
  let format = mask_decimal(input);
  return format.length > 9 ? format.slice(0,9) : format;
}

function mask_limite_d2(input){

  if(input.value.length > 9)
  return input.value.slice(0,9);

  let format = mask_decimal(input);
  return format.length > 9 ? format.slice(0,9) : format;
}
function mask_limite_d3(input){

  if(input.value.length > 9)
  return input.value.slice(0,9);

  let format = mask_decimal(input);
  return format.length > 9 ? format.slice(0,9) : format;
}
function mask_limite_d4(input){

  if(input.value.length > 9)
  return input.value.slice(0,9);

  let format = mask_decimal(input);
  return format.length > 9 ? format.slice(0,9) : format;
}
function mask_limite_d5(input){

  if(input.value.length > 9)
  return input.value.slice(0,9);

  let format = mask_decimal(input);
  return format.length > 9 ? format.slice(0,9) : format;
}
function mask_limite_d6(input){

  if(input.value.length > 9)
  return input.value.slice(0,9);

  let format = mask_decimal(input);
  return format.length > 9 ? format.slice(0,9) : format;
}
function mask_acrescimo(input){
    return mask_decimal(input);
}
function mask_desconto(input){
  return mask_decimal(input);
}
function mask_valor_bruto(input){
  return mask_decimal(input);
}
function mask_valor_liquido(input){
  return mask_decimal(input);
}
function mask_valor(input){
  return mask_decimal(input);
}
function mask_juros(input){
  return mask_decimal(input);
}

export function mask_decimal(input){

  if(typeof input === "undefined" || input == null)
  return '';

  if(input.value == '')
  return '';

  let valor = '';

  if(typeof input.value !== "undefined"){
    valor = input.value;
  } else {
    valor = input;
  }

  let v = valor.toString();
  
  v = v.replace(/\D/g, '');

  if(v.length == 0)
  return 0;

  //if(v.length <= 3)
  //return parseFloat(v.toString().replace(".","").replace(",", ".")) > 0 ? v : "-"+v;

  v = parseFloat(v.replace(/(\d{1,2})$/, '.$1'));

/*
	v = v.replace(/(\d{1,2})$/, ',$1');
  v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  */

  v = v.toLocaleString("pt-BR", {minimumFractionDigits: 2, currency: 'BRL', style: 'decimal'});

  return parseFloat(parseDecimal(valor)) >= 0 ? v : "-"+v;
}

export function parseDecimal(v){
  return v.toString().replace(/\./g,"").replace(/\,/g,".");
}

export function mask_integer(input){

  if(typeof input === "undefined" || input == null)
  return 0;

  let valor = '';

  if(typeof input.value !== "undefined"){
    valor = input.value;
  } else {
    valor = input;
  }

  if(input.value == "")
  return v;

	let v = valor+'';
  v = v.replace(/\D/g, '');
  
  return parseInt(v);

}

export function mask_cc_birthdate(input){
  return mask_date(input);
}

function mask_date(input){
  var re = /^([\d]{2})\/*([\d]{2})\/*([\d]{4})$/; // Pode usar ? no lugar do *

	return input.value.replace(re,"$1/$2/$3");

}
function mas_cc_number(input){

  let v = input.value;

  v = v.replace(/\D/g, "");
	v = v.replace(/(\d{4})(\d)/, "$1.$2");
	v = v.replace(/(\d{4})(\d)/, "$1.$2");
	v = v.replace(/(\d{4})(\d)/, "$1.$2");
	v = v.replace(/(\d{4})(\d{4})(\d{4})(\d)/, "$1.$2.$3.$4");

	return v;
}
function mask_nascimento(input){
return mask_date(input);
 }

function mask_cep(input){
  var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *

	return input.value.replace(re,"$1.$2-$3");

}
function mask_celular(input){
  if(input.value.toString().length > 14){
    return input.value.toString().slice(0,13);
  }
  let v = input.value;
  v=v.replace(/\D/g,"");             //Remove tudo o que não é dígito
  //v=v.replace(/^(\d{2})(\d)/g,"($1)\s$2"); //Coloca parênteses em volta dos dois primeiros dígitos
  v=v.replace(/(\d{4,5})(\d{4})$/,"$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
  return v;
}
function mask_telefone2(input){
  return mask_telefone(input);
}

function mask_phone(input){
  return mask_telefone(input);
}

function mask_cc_phone(input){
  return mask_telefone(input);
}

function mask_telefone(input){
  if(input.value.toString().length > 15){
    return input.value.toString().slice(0,15);
  }
  let v = input.value;
  v=v.replace(/\D/g,"");             //Remove tudo o que não é dígito
  //v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  v=v.replace(/(\d{4})(\d{4})$/,"$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
  return v;
}
export function mask_cc_cpf(input){
  return mask_cpf(input);
}
function mask_cpf(input){
        return input.value
          .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
          .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})/, '$1-$2')
          .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}
function mask_cnpj(input){
  if(input.value.toString().length > 18){
    return input.value.toString().slice(0,18);
  }
  return input.value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
}
function mask_horario(input){

  if(typeof input === "undefined" || input == null)
  return 0;

  let valor = '';

  if(typeof input.value !== "undefined"){
    valor = input.value;
  } else {
    valor = input;
  }

  valor = valor.replace(/[^1-9]/g, '');
  
  valor = valor.padStart(4, '0');
  valor = valor.substring((valor.length)-4);

  return valor.replace(/(\d{2})(\d{2})/,"$1:$2");
}
function mask_horario_de(input){
  return mask_horario(input);
}
function mask_horario_ate(input){
  return mask_horario(input);
}

function mask_hsaida(input){
  return mask_horario(input);
}

export function inputMask(input){

  try{
    let maskedInput = eval("mask_"+input.name);
    if(typeof maskedInput === "undefined")
    return input.value;
    else
    return eval("mask_"+input.name)(input);

  } catch(e){
    if (e instanceof ReferenceError) {
      return input.value;
    }
  }

}
export default inputMask;