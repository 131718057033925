import React from 'react';
import './Modal.scss';
import './ChargeModal.scss';
import {CFG, axiosHeader, qrCodeValidate} from './CFG';
import axios from 'axios';
import {inputChangeHandler, cepChangeHandler, isValid, radioSwitchHandler, selectChangeHandler} from './CustomForm';
import {Modal, Button, Tab, Col, Row, Nav, FormControl} from 'react-bootstrap';
import url_btn_close from './img/icons/close.png';
import EstadoSelect from './EstadoSelect';
import {displayPsError, psRealToken, psEmail, psToken, psHeader, psNotificationUrl, checkout, buildCheckout, loadPagSeguroScripts} from './PagSeguroCheckout';
import { mask_decimal } from './util/inputMask';
import { startSessao, setUser, sessionGet } from './usuario/Auth';
import {meses} from './util/date';
import PriceTile from './PriceTile';
import ReactToPrint from 'react-to-print';

import {requestException} from './util/exception';

var parse = require('html-react-parser');
var QRCode = require('qrcode.react');

class ChargeModal extends React.Component{

    constructor(props) {

      super(props);

      this.state = { 
        paymentLink: undefined,
        step_status : [
          1,
          1,
          1,
          7,
          5,
          5
        ],
        session: '',
        role_id:1,
        email: '',
        name: '',
        cc_bank_name: 'itau',
        cc_name: '',
        cc_brand: '',
        cc_cvv_size: 3,
        cc_cpf: '',
        cc_birthdate: '',
        cc_payment_method: 'boleto',
        cc_payment_method_list: [],
        cc_qty_installment: 1,
        cc_qty_installment_list: [],
        cc_month: "",
        cc_year: "",
        cc_email: '',
        surname: '',
        gender: 'M',
        ddd: '',
        cep: '',
        numero: '',
        logradouro: '',
        cidade: '',
        bairro: '',
        cc_ddd: '',
        phone: '',  
        cc_phone: '',  
        password: '',  
        method_id: 2,
        id: undefined,
        amount: 0,
        number_people: 0,
        step_title: '1. Dados da oferta',
        step_title_list: [
          '1. Dados da oferta', '2. Dados pessoais', '3. Aceite do contrato', '4. Pagamento', '5. Comprovante'
        ],
        step_key: 0,
        charge: {
          
        },
        extras_available: {
          boarding: 'Embarque', 
          room: 'Hospedagem', 
          age: 'Faixa etária'
        },
        cart: {
          boarding: {}, 
          room: {}, 
          age: {}
      },
        trip: {
          id: undefined,
          amount_table: undefined,
          file: {
            id: undefined
          },
          contract: ''
        },
        loading: false,
        finished: false,
        cc_currency: 'BRL',
        cc_country: 'BRA',
        senderHash: '',
        cardToken: '',
        user: {
          id: undefined
        },
        validator: {
          card_valid: undefined,
        }     
      };
      this.isValid = isValid.bind(this);
      this.cepChangeHandler = cepChangeHandler.bind(this);
      this.selectChangeHandler = selectChangeHandler.bind(this);
      this.inputChangeHandler = inputChangeHandler.bind(this);
      this.radioSwitchHandler = radioSwitchHandler.bind(this);
      this.buildCheckout = buildCheckout.bind(this);
      this.checkout = checkout.bind(this);
      this.loadPagSeguroScripts = loadPagSeguroScripts.bind(this);
      this.setUser = setUser.bind(this);

      this.loadPagSeguroScripts();

    }

    brandChangeHandler = (event) => {
      this.setState({ cc_brand: event.target.value });

    }

    componentDidMount(){

      let email = sessionGet('email');
      if(email != null)
      this.setUser(email);

      let REF = this;

    }

    componentDidUpdate(prevProps, prevState){

      if(typeof this.props.trip !== "undefined" && this.props.trip.id !== this.state.trip.id){
        let REF = this;
        this.setState({ step_key: 0, finished: false, trip_id: this.props.trip.id, trip: {
    
          ...REF.state.trip,
          file: {
            id: undefined
          },
          ...REF.props.trip,
          amount_table: JSON.parse(this.props.trip.amount_table),
          cart: {}, amount: 0
         }});
      }

      if(prevState.session !== this.state.session){
        window.PagSeguroDirectPayment.setSessionId(this.state.session);
      }

      if(prevProps.reset !== this.props.reset){
        this.setState({ step_key: 0,  finished: false, id: undefined, trip: { id: undefined,  amount_table: undefined } });
      }

      if(prevState.user.id !== this.state.user.id){
        let REF = this;
        this.setState({ 
            user_id: REF.state.user.id,
            email: REF.state.user.email,
            name: REF.state.user.name,
            surname: REF.state.user.surname,
            gender: REF.state.user.gender,
            ddd: REF.state.user.ddd,
            phone: REF.state.user.phone,
            password: REF.state.user.password_decode
        })
      }

    }

    updateAmountTotal = () => {
      let REF = this;
      let up_total = 0.0;
      let entries = Object.entries(this.state.cart);
      console.log(entries);

      entries.map(([type,items_obj]) => {

        let items = Object.entries(items_obj);

        console.log(items);

        items.map(([k, item], index) => {

        if(typeof item.quantity === "undefined" || typeof k === "undefined")
        return null;

        let up_amount = REF.getAmountByKey(type, k);

        let q = parseFloat(item.quantity);

        if(q > 0){
          if(type === "discount"){
            up_total = parseFloat(up_total) - (parseFloat(up_amount) * q );
            if(up_total < 0)
            up_total = 0;
          }
          else
          up_total = parseFloat(up_total) + (parseFloat(up_amount) * q );
        }
      });
    });

    console.log(up_total);

    setTimeout(() =>  {
      REF.setState({ amount: up_total.toFixed(2) });
      setTimeout(() => REF.getInstallmentOptions(), 2000);
    }, 1000);
     
    }

    contractFormatted = () => {
      let REF = this;
      let contract = Object.assign("", REF.state.trip.contract);
      
      contract.replace(/\$CONTRATANTE/g, REF.state.name.toUpperCase());
      contract.replace(/\$METODO/g, REF.state.cc_payment_method.toUpperCase());
      contract.replace(/\$TOTAL/g, 'R$ '+mask_decimal(REF.state.total));
      contract.replace(/\$TOTAL_PARCELA/g, 'R$ '+mask_decimal(REF.state.cc_amount_installment));
      contract.replace(/\$QTD_PARCELA/g, REF.state.cc_qty_installment);
      return contract;
    }

    saveCharge = () => {

      let REF = this;

     let data = {};
     Object.assign(data, this.state);
     data.cart = JSON.stringify(data.cart);
     data.contact = this.contractFormatted();

      axios.post(CFG.URL_API+'/charge', data, axiosHeader())
      .then((response) => {

        startSessao(REF.state.email, REF.state.password);

        REF.setState( { id: response.data.id, user: response.data.user } );
         
      }).catch((response) => {
        
      });
    }

    stepAdvance = (e) => {

      if(!this.isValid())
      return window.alert('ATENÇÃO! \n Observe pendências do formulário');

     let REF = this;

      if(this.state.step_key > 3){

        if(!this.state.validator.card_valid)
        return window.alert('Cartão inválido');
        else
        this.setState({ finished: true, step_key: 0 });
        
      }
      else {
        let add = this.state.step_key + 1;
        this.setState({ step_key: add });

        this.stepAction(add);
      }

      let status_id = this.state.step_status[this.state.step_key];
      this.setState({ status_id });

    }

    sendContractMail = () => {
      let REF = this;

      let data = this.state;
 
       axios.put(CFG.URL_API+'/charge-contract/'+data.id, data, axiosHeader())
       .then((response) => {
 
       }).catch((response) => {
 
       });
    }

    stepAction = (step) => {
      let REF = this;
      switch(step){
        case 2:
          setTimeout(() => REF.saveCharge(), 1000);
          setTimeout(() => REF.newSession(), 3000);
          break;
        case 3:
          setTimeout(() => REF.sendContractMail(), 3000);

          break;
          case 4:

            if(this.state.validator.card_valid){

              REF.setState({ 
                validator: {
                ...REF.state.validator,
                cc_number: true,
                cc_month: true,
                cc_year: true
              }
            });

            } else {
              if(REF.state.cc_payment_method === "credit_card"){
                window.alert('O cartão foi considerado INVÁLIDO');
                break;
              }
            }

            setTimeout( () =>  {

              if(parseInt(REF.state.amount) > 0){
                REF.saveCharge();
                REF.buildCheckout();
          
                setTimeout( () =>  {
                  REF.checkout();
                }, 3000);
              } else {
                let add = REF.state.step_key - 1;
                REF.setState({ step_key: add });
                window.alert("Valor final não pode ser zero. Escolha o que deseja adquirir no passo \"Pagamento\"." );
              }           
            
             }, 1000);

            break;
      }
    }

    //step 2 action
    getInstallmentOptions = () => {
      let REF = this;
      window.PagSeguroDirectPayment.getInstallments({
        amount: REF.state.amount,
        maxInstallmentNoInterest: 2,
        brand: REF.state.cc_brand,
        success: function(response){

          if(response.error){
            alert(Object.entries(response.errors).map(([code, message]) => message));
          }

          let options = Object.values(response.installments);
          if(typeof options[0] !== "undefined")
          REF.setState({ cc_qty_installment_list: options[0] });
             // Retorna as opções de parcelamento disponíveis
       },
        error: function(response) {
             // callback para chamadas que falharam.

       },
        complete: function(response){
            // Callback para todas chamadas.
       }
    });
  
  }

    getNextYears(){
      let d = new Date();
      let y = d.getFullYear();
      let list = [];
      for(let c = y; c < y+20; c++){
        list.push(c);
      }
      return list;
    }

    numCartaoChangeHandler = (event) => {

      let REF = this;

      event.persist();

      let cardBin = event.target.value.toString().replace(/\D/g,'').substr(0,6);

      if(cardBin.toString().length >= 6){
        window.PagSeguroDirectPayment.getBrand({
          cardBin,
          success: function(response) {
            //bandeira encontrada
            REF.setState({ cc_bin: cardBin, cc_brand: response.brand.name, cc_cvv_size: response.brand.cvvSize,
            validator: { ...REF.state.validator, card_valid: true } });
  
            setTimeout(() => REF.getInstallmentOptions(), 1000);
  
          },
          error: function(response) {
            requestException(response);
            REF.setState({ cc_bin: '', cc_brand: '', cc_cvv_size: 3, validator: { ...REF.state.validator, card_valid: false } });
            //tratamento do erro
          },
          complete: function(response) {
            //tratamento comum para todas chamadas
          }
      });
      } else {
        REF.setState({ cc_bin: '', cc_brand: '', cc_cvv_size: 3, validator: { ...REF.state.validator, card_valid: false } });
      }

      REF.inputChangeHandler(event);

      REF.generateToken();
    }

    ccYearChangeHandler = (event) => {
      event.persist();
      this.inputChangeHandler(event);

      this.generateToken();
    }
    ccMonthChangeHandler = (event) => {
      event.persist();
      this.inputChangeHandler(event);

      this.generateToken();
    }

    generateToken = () => {

      let REF = this;

      if(REF.state.cc_number == "" || REF.state.cc_brand == "" || REF.state.cc_cvv == "" || REF.state.cc_month == "" || REF.state.cc_year == "")
      return null;

      let dt = new Date();

      if(REF.state.cc_year === "" || REF.state.cc_month === "" || REF.state.cc_year < dt.getFullYear() ||  (REF.state.cc_year == dt.getFullYear() && REF.state.cc_month < (dt.getMonth()+1))){
        REF.setState({ validator: { 
          ...REF.state.validator,
          cc_month: false,
          cc_year: false
         } });
      }

      setTimeout(() => {
         
        window.PagSeguroDirectPayment.createCardToken({
          cardNumber: REF.state.cc_number, // Número do cartão de crédito
          brand: REF.state.cc_brand, // Bandeira do cartão
          cvv: REF.state.cc_cvv, // CVV do cartão
          expirationMonth: REF.state.cc_month, // Mês da expiração do cartão
          expirationYear: REF.state.cc_year, // Ano da expiração do cartão, é necessário os 4 dígitos.
          success: function(response) {
               // Retorna o cartão tokenizado.
               REF.setState({ cardToken: response.card.token });
          },
          error: function(response) {
            console.log(displayPsError(response));

            REF.setState({ validator: { ...REF.state.validator, card_valid: false } });
          },
          complete: function(response) {
               // Callback para todas chamadas.

               let card_valid = REF.state.validator.card_valid;
          
            if(response.error == true){
              REF.setState({ validator: { 
                ...REF.state.validator,
                cc_number: false,
                cc_month: false,
                cc_year: false,
                cc_cvv: false,
                card_valid: false
               } });

            } else {
              REF.setState({ validator: { ...REF.state.validator, card_valid: true,               cc_number: true,  cc_cvv: true,  cc_month: true,    cc_year: true } });
            }

          }
        });
      }, 2000);
    }

    cvvChangeHandler = (event) => {
      let REF = this;

      if(parseInt(event.target.value.toString().length) !== parseInt(this.state.cc_cvv_size)){
        event.target.classList.add('is-invalid');
        event.target.classList.remove('is-valid');
        this.setState({ validator: { ...REF.state.validator,  cc_cvv: false } });
      } else {
     
        event.target.classList.remove('is-invalid');
        event.target.classList.add('is-valid');
        this.setState({ validator: { ...REF.state.validator, cc_cvv: true } });

       REF.generateToken();
 
      }

    event.persist();
    this.inputChangeHandler(event);

    }

    getAmountByKey = (type, key) => {
  
      if(Object.getOwnPropertyNames(this.state.trip.amount_table).length === 0)
      return 0;
 
      console.log(type, key);

      if(typeof this.state.trip.amount_table[type][key] !== "undefined")
        return this.state.trip.amount_table[type][key].amount;
        else
        return 0;
  }

  cartChangeHandler = (event, type, choice, value) => {

    event.persist();
    let REF = this;
    let up_cart = this.state.cart;

    if(typeof type === "undefined")
    return null;

    if(typeof up_cart[type] === "undefined"){
      up_cart[type] = {};
      up_cart[type][choice] = {};
    }
  
    if(typeof up_cart[type][choice] === "undefined")
    up_cart[type][choice] = {};

    up_cart[type][choice].quantity = value;

    this.setState({ cart: {
      ...up_cart, [type]: {
        ...up_cart[type], [choice]:  {
          ...REF.state.trip.amount_table[type][choice],
          quantity: value
        }
      }
    }});

    setTimeout(() => REF.updateAmountTotal(), 1500);
  }

  installmentChangeHandler = (event) => {
    let installment_pick = this.state.cc_qty_installment_list[event.target.value];
    this.setState({ amount: installment_pick.totalAmount.toFixed(2), cc_qty_installment: installment_pick.quantity, cc_amount_installment: installment_pick.installmentAmount.toFixed(2) });
  }

    tripCartList = () => {

      let REF = this;

      const displayInput = (k, n) => {

        return (<input placeholder="Qtd." className="form-control" value={REF.state.cart[k].quantity} name={k} id={`f-quantity_${k}_${n}`} type="number" min="1" onChange={ (event) => REF.cartChangeHandler(event, k, n, event.target.value)}/>);
      }

      if(typeof this.state.trip.amount_table === "undefined")
      return (<>Não foi possível carregar a tabela de preço</>);

      if(Object.keys(this.state.trip.amount_table).length == 0)
      return null;

      let price_table = Object.entries(this.state.trip.amount_table);

      return price_table.map(([k, list]) => {

        let list_entries = Object.entries(list);
      
       return (<>
        { list_entries.map(([n, price]) => {
          
          if(typeof REF.state.cart[k] === "undefined" || price == null || price.disabled == "true")
          return (<></>);

          return (
            <Row className="item" key={k+"-"+n}>
              <Col md={9} className="f-flex">
                <label className="my-auto"  htmlFor={`f-quantity_${k}_${n}`}>
              + {REF.state.extras_available[k]} {price.name}: 
              <br/>
              <span hidden={price.amount == 0}> <strong>R$ {mask_decimal(parseFloat(price.amount).toFixed(2))} cada  </strong></span>
              <span hidden={price.amount > 0}> <strong>Gratuito  </strong></span>
              </label>
                </Col>
                <Col md={3}>
                  {displayInput(k,n)}
                </Col>
           </Row>
          );
        } ) 
      }
       
      </>);
    } );

    }

    methodChangeHandler = (e) => {

      e.persist();

      let REF = this;

      if(e.target.value === "credit_card")
      REF.setState({ cc_bin: '', cc_brand: '', cc_cvv_size: 3, validator: { ...REF.state.validator, card_valid: false } });
      else
      REF.setState({ cc_bin: '', cc_brand: '', cc_cvv_size: 3, validator: { ...REF.state.validator, card_valid: true } });

      this.inputChangeHandler(e);

    }
    
  render () {

    let REF = this;

    const getInstallmentChoice = (value) => {
      return mask_decimal(parseFloat(this.state.amount) + parseFloat(value));
    };

    const isInvalid = (field) => {
      if(REF.state.validator[field] === false){
          return "is-invalid";
      }
    };

    const isCompleteClass = (step) => {
      return step < this.state.step_key ? "complete": "";
    }

    const tabDisabledClass = (step) => {
      if(step > this.state.step_key || this.state.finished == false)
      return true;
      else
      return false;
    }

    const tabActiveClass = (step) => {
      if(step == this.state.step_key)
      return true;
      else
      return false;
    };

    const printREF = React.createRef();

    return (
      <>
       <Modal   
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title"
        className={this.state.step_key == 4 ? "ChargeModal finished" : "ChargeModal"}
      >
        <form>
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
            {this.state.step_title_list[this.state.step_key]}
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={() => this.props.onHide(false)} />

        </Modal.Header>
        <Modal.Body className="py-4">       

            <PriceTile amount={this.state.amount} />

        <Tab.Container id="left-tabs-example" activeKey={this.state.step_key} >
  <Row className="m-0">
    <Col id="nav-purchase">
      <Nav variant="pills" className="flex-column" >
      <Nav.Item>
          <Nav.Link className={isCompleteClass(0)} active={tabActiveClass(0)} disabled={tabDisabledClass(0)} eventKey={0}><span className="badge">1.</span> Dados da oferta</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className={isCompleteClass(1)} active={tabActiveClass(1)} disabled={tabDisabledClass(1)} eventKey={1}><span className="badge">2.</span> Dados pessoais</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className={isCompleteClass(2)} active={tabActiveClass(2)} disabled={tabDisabledClass(2)} eventKey={2}><span className="badge">3.</span> Aceite do contrato</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className={isCompleteClass(3)} active={tabActiveClass(3)} disabled={tabDisabledClass(3)} eventKey={3}><span className="badge">4.</span> Pagamento</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className={isCompleteClass(4)} active={tabActiveClass(4)} disabled={tabDisabledClass(4)} eventKey={4}><span className="badge">5.</span> Comprovante</Nav.Link>
        </Nav.Item>
        <Nav.Item className="text-center" >
          <Button hidden={this.state.step_key >= 3} className="mx-auto my-2" variant="primary" onClick={ this.stepAdvance }>
              Prosseguir
          </Button>
          <Button hidden={this.state.step_key != 3}  onClick={ this.stepAdvance } className="mx-auto my-2" variant="success">
            Finalizar
          </Button>

          <a target="_blank" href={this.state.paymentLink} className="btn btn-primary btn-lg" hidden={this.state.cc_payment_method !== "boleto" || typeof this.state.paymentLink === "undefined"}>BAIXAR BOLETO</a>

          <ReactToPrint
              trigger={() =>
                <Button hidden={this.state.step_key < 4} onClick={() => window.print()} className="mx-auto my-2" size="lg" variant="warning">
                Imprimir QRCode
              </Button>}
              content={() => printREF.current}
            />

        </Nav.Item>
      </Nav>
    </Col>
    
      <Tab.Content>
        <Tab.Pane eventKey="0">
          <Row>
            <Col md={4} className={typeof this.state.trip.file.id === "undefined" ? "d-none d-md-none" : "d-none d-md-block"}>
              <img src={this.state.trip.file.url} className="img-fluid"/>
            </Col>
            <Col md={typeof this.state.trip.file.id === "undefined" ? "12" : "8"}>
            <h4>{this.state.trip.title}</h4>
          {this.state.trip.details}
          <hr/>
          <Row>
            <Col hidden={this.state.trip.charge_max == null}>
              Lotação: {this.state.trip.charge_max}
            </Col>
            <Col>
              Partida: {this.state.trip.end_at_format}
            </Col>
            <Col>
              Volta: {this.state.trip.end_at_format}
            </Col>
          </Row>
            </Col>
          </Row>
  
        </Tab.Pane>
        <Tab.Pane eventKey="1">
        <Row>
        <Col md={6}>
           
           <label htmlFor="f-email">
         E-mail para seu login
           </label>
           <input readOnly={typeof this.state.user.id !== "undefined"} className="form-control" name="email" id="f-email" type="text" value={this.state.email} onChange={this.inputChangeHandler} />
        
             </Col>
        <Col md={6}>
           
           <label htmlFor="f-password">
        Defina uma senha <strong>de 4 números</strong> para seu login
           </label>
           <input readOnly={typeof this.state.user.id !== "undefined"} className="form-control" name="password" id="f-password" type="text" size="4" value={this.state.password} onChange={this.inputChangeHandler} />
        
             </Col>
        </Row>

        <hr/>

        <Row >
          <Col md={6}>
           
        <label htmlFor="f-name">
      Nome
        </label>
        <input readOnly={typeof this.state.user.id !== "undefined"}  className="form-control" name="name" id="f-name" type="text" value={this.state.name} onChange={this.inputChangeHandler}/>
     
          </Col>

          <Col md={6}>
           
           <label htmlFor="f-surname">
         Sobrenome
           </label>
           <input  readOnly={typeof this.state.user.id !== "undefined"} className="form-control" name="surname" id="f-surname" type="text" value={this.state.surname} onChange={this.inputChangeHandler}/>
             </Col>

          </Row>
        <Row >

      <Col xs={3}>
           
           <label htmlFor="f-ddd">
         DDD
           </label>
           <input   readOnly={typeof this.state.user.id !== "undefined"} className="form-control" name="ddd" id="f-ddd" type="text" value={this.state.ddd} onChange={this.inputChangeHandler}/>
        
             </Col>
             <Col md={6}>
              
              <label htmlFor="f-phone">
            Telefone
              </label>
              <input   readOnly={typeof this.state.user.id !== "undefined"} className="form-control" name="phone" id="f-phone" type="text" value={this.state.phone} onChange={this.inputChangeHandler} />
           
                </Col>

        </Row>

        <Row >
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="2">
          {this.state.trip.contract !== null ? parse(this.state.trip.contract) : ""}
      </Tab.Pane>
        <Tab.Pane eventKey="3">

          {this.tripCartList()}
    
        <Row>

        <Col xs={12} md={4}>
           
           <label htmlFor="f-cc_payment_method">
         Meio de pagamento
           </label>
           <select value={this.state.cc_payment_method} name="cc_payment_method" className="form-control" onChange={this.methodChangeHandler}>
           <option value="">Selecione</option>

        <option value="boleto">BOLETO BANCÁRIO</option>
        <option value="credit_card">CARTÃO DE CRÉDITO</option>
        </select>        
             </Col>

             <Col xs={12} md={8} hidden={this.state.cc_payment_method === "boleto"}>
           
           <label htmlFor="f-cc_number">
         Número do cartão
           </label>
           <FormControl className={`form-control ${isInvalid('cc_number')}`} name="cc_number" id="f-cc_number" type="text" value={this.state.cc_number} onChange={this.numCartaoChangeHandler} onBlur={this.numCartaoChangeHandler}/>
        
             </Col>

             </Row>
       
             <Row>

             <Col md={4} hidden={this.state.cc_payment_method === "boleto"}>
           
           <label htmlFor="f-cc_brand" >
         Bandeira do cartão
           </label>
           <select value={this.state.cc_brand} name="cc_brand" className="form-control" onChange={this.brandChangeHandler}>
           <option value="">Selecione</option>

          <option value="mastercard">MasterCard</option>
          <option value="visa">VISA</option>
          <option value="elo">ELO</option>
          <option value="cabal">Cabal</option>
          <option value="hipercard">Hiper (Hipercard)</option>
          <option value="dinners">Dinners</option>
          <option value="brasilcard">BrasilCard</option>
          <option value="aura">Aura</option>
          <option value="up">Up</option>
          <option value="personalcard">PersonalCard</option>
          <option value="sorocred">SoroCred</option>
          <option value="valecard">ValeCard</option>
        </select>        
             </Col>
        
             <Col md={4} hidden={this.state.cc_payment_method === "boleto"}>
           
           <label htmlFor="f-cc_month_valid">
         Mês val.
           </label>
           <select className={`form-control ${isInvalid('cc_month')}`} name="cc_month" value={this.state.cc_month} onChange={this.ccMonthChangeHandler} onBlur={this.ccMonthChangeHandler}>
           <option value="">Selecione</option>
          {meses.map((m,k) => {
          return (<option key={k} value={k+1}>{m}</option>);
          })}

           </select>
        
             </Col>

             <Col md={4} hidden={this.state.cc_payment_method === "boleto"}>
           
           <label htmlFor="f-cc_year_valid">
         Ano val.
           </label>
           <select className={`form-control ${isInvalid('cc_year')}`} name="cc_year" value={this.state.cc_year} onChange={this.ccYearChangeHandler}  onBlur={this.ccYearChangeHandler}>
           <option value="">Selecione</option>
           {this.getNextYears().map((y,k) => {
          return (<option key={y} value={y}>{y}</option>);
          })}
           </select>
        
             </Col>

        </Row>

        <Row >
        <Col md={4} hidden={this.state.cc_payment_method === "boleto"}>
           
           <label htmlFor="f-cc_cvv">
          CVV (verso)
           </label>
           <FormControl  className={`form-control ${isInvalid('cc_cvv')}`} name="cc_cvv" id="f-cc_cvv" type="text" value={this.state.cc_cvv} onChange={this.cvvChangeHandler}  onBlur={this.inputChangeHandler}/>
        
             </Col>
        <Col md={4} hidden={this.state.cc_payment_method === "boleto"}>
           
           <label htmlFor="f-cc_qty_installment">
         Parcelamento desejado
           </label>
           <select name="cc_qty_installment" className="form-control" onChange={this.installmentChangeHandler}>
           <option value="">Selecione parcelamento desejado</option>
           {this.state.cc_qty_installment_list.map((i, key) => {
           return (<option key={key} value={key}>{i.quantity} x {mask_decimal(i.installmentAmount.toFixed(2))} {i.interestFree ? "s/ juros" : "c/ juros"}</option>);
           })}
           </select>
        
             </Col>
             
        </Row>

        <Row>
          <Col md={4}>
           
        <label htmlFor="f-cc_name">
      Nome <small className="text-muted" hidden={this.state.cc_payment_method === "boleto"}>*escrito no cartão</small>
        </label>
        <input className="form-control" name="cc_name" id="f-cc_name" type="text" value={this.state.cc_name} onChange={this.inputChangeHandler}/>
     
          </Col>
          <Col md={4}>
           
           <label htmlFor="f-cc_cpf">
         CPF
           </label>
           <input className="form-control" name="cc_cpf" id="f-cc_cpf" type="text" value={this.state.cc_cpf} onChange={this.inputChangeHandler} />
        
             </Col>

             <div className="col-md-4">
        <label htmlFor="f-cc_birthdate">Nascimento</label>
          <input name="cc_birthdate" id="f-cc_birthdate" className="form-control" value={this.state.cc_birthdate} onChange={this.inputChangeHandler} />
        </div>
        </Row>
        <Row>
          <Col xs={3} md={2} >
           
        <label htmlFor="f-cc_ddd">
      DDD
        </label>
        <input className="form-control" name="cc_ddd" id="f-cc_ddd" type="text" value={this.state.cc_ddd} onChange={this.inputChangeHandler}/>
     
          </Col>
          <Col xs={9} md={5}>
           
           <label htmlFor="f-cc_phone">
         Telefone
           </label>
           <input className="form-control" name="cc_phone" id="f-cc_phone" type="text" value={this.state.cc_phone} onChange={this.inputChangeHandler} />
        
             </Col>
             <Col xs={12} md={5}>
           
           <label htmlFor="f-cc_email">
         E-mail
           </label>
           <input className="form-control" name="cc_email" id="f-cc_email" type="text" value={this.state.cc_email} onChange={this.inputChangeHandler} />
        
          </Col>
        </Row>
        <Row>
        <div className="col-md-3">     
          <label htmlFor="f-cep">
            CEP
          </label>
          <input className="form-control" name="cep" id="f-cep" type="text" value={this.state.cep} onChange={this.cepChangeHandler}/>
        </div>
        <div className="col-md-7">         
          <label htmlFor="f-logradouro">
            Endereço
          </label>
          <input className="form-control" name="logradouro" id="f-logradouro" type="text" value={this.state.logradouro} onChange={this.inputChangeHandler}/>
        </div>
        <div className="col-md-2">         
          <label htmlFor="f-numero">
            Número
          </label>
          <input className="form-control" name="numero" id="f-numero" type="tel" value={this.state.numero} onChange={this.inputChangeHandler}/>
        </div>
        </Row>
      <Row>
        <div className="col-md-5">         
          <label htmlFor="f-name">
            Bairro
          </label>
          <input className="form-control" name="bairro" id="f-bairro" type="text" value={this.state.bairro} onChange={this.inputChangeHandler}/>
        </div>
        <div className="col-md-5">         
          <label htmlFor="f-cidade">
            Cidade
          </label>
          <input className="form-control" name="cidade" id="f-cidade" type="text" value={this.state.cidade} onChange={this.inputChangeHandler}/>
        </div>
        <div className="col-md-2">         
          <label htmlFor="f-uf">
            Estado
          </label>
          <EstadoSelect change={this.inputChangeHandler} uf={this.state.uf} />
        </div>
        </Row>
   
      <Row >
          <Col xs={12} >
            <label htmlFor="f-cc_details">Detalhes e Observações</label>
            <textarea className="form-control" name="cc_details" id="f-cc_details" value={this.state.cc_details} onChange={this.inputChangeHandler}></textarea>
          </Col>
        </Row>
        </Tab.Pane>
        <Tab.Pane eventKey="4" ref={printREF}>
        <QRCode  value={qrCodeValidate+'/'+this.state.id} className="d-block m-auto my-4" size={200} fgColor={"black"} />
  <p className="mt-2 text-center">Reserva <strong>#{this.state.id}</strong> solicitada com sucesso!</p>
          <p className="text-success text-center">Imprima em folha ou printe o QRCode e apresente no embarque.</p>
    
        </Tab.Pane>
     
        </Tab.Content>
  </Row>
</Tab.Container>
        </Modal.Body>
    </form>
      </Modal>
      </>
    );
  }

  newSession = () => {
    
    let REF = this;

    let url = CFG.URL_API+"/pagseguro-start-session";

    let data = this.psPayloadString();

      this.setState({ pagseguro_payload: data });

   axios.get(url).then(response => {
     window.PagSeguroDirectPayment.setSessionId(response.data[0]);
     REF.setState({ session: response.data[0] });
   }).catch(error => console.log);

  }
  
  psPayloadString = () => ("paymentMode=default"
  +"&paymentMethod="+this.state.cc_payment_method
  +"&bankName="+this.state.cc_bank_name
  +"&receiverEmail="+psEmail
  +"&currency=BRL"
  +"&extraAmount=0.00"
  +"&itemId1="+this.state.id
  +"&itemDescription1="+this.state.trip.title
  +"&itemAmount1="+parseFloat(this.state.amount).toFixed(2)
  +"&itemQuantity1=1"
  +"&notificationURL="+psNotificationUrl
  +"&reference="+this.state.id
  +"&senderName="+this.state.cc_name
  +"&senderCPF="+this.state.cc_cpf.replace(/\D/g,"")
  +"&senderAreaCode="+this.state.cc_ddd
  +"&senderPhone="+this.state.cc_phone.replace(/\D/g,"")
  +"&senderEmail="+this.state.cc_email
 // +"&senderEmail=comprador@sandbox.pagseguro.com.br"
  +"&senderHash="+this.state.senderHash
  +"&creditCardToken="+this.state.cardToken
  +"&installmentQuantity="+this.state.cc_qty_installment
  +"&installmentValue="+this.state.cc_amount_installment
  //+"&noInterestInstallmentQuantity=1"
  +"&creditCardHolderName="+this.state.cc_name
  +"&creditCardHolderCPF="+this.state.cc_cpf.replace(/\D/g,"")
  +"&creditCardHolderBirthDate="+this.state.cc_birthdate
  +"&creditCardHolderAreaCode="+this.state.cc_ddd
  +"&creditCardHolderPhone="+this.state.cc_phone.replace(/\D/g,"")
  +"&billingAddressStreet="+this.state.logradouro
  +"&billingAddressNumber="+this.state.numero
  +"&billingAddressComplement="
  +"&billingAddressDistrict="+this.state.bairro
  +"&billingAddressPostalCode="+this.state.cep.replace(/\D/g,"")
  +"&billingAddressCity="+this.state.cidade
  +"&billingAddressState="+this.state.uf
  +"&billingAddressCountry=BRA"
  +"&shippingAddressRequired=false").replace(/(\r\n|\n|\r)/gm,"");

  //step 1
  psAuthorize = () => {

    let data = this.psPayloadString();

      this.setState({ pagseguro_payload: data });

    //axios.post('https://ws.sandbox.pagseguro.uol.com.br/v2/checkout/?email='+psEmail+'&token='+psToken, data, psHeader);
    axios.post('https://ws.pagseguro.uol.com.br/v2/checkout/?email='+psEmail+'&token='+psRealToken, data, psHeader);
  
  }  

}

export default ChargeModal;