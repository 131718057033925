import React from 'react';
import axios from 'axios';
import {sessionGet} from './usuario/Auth';
import {requestException} from './util/exception';
import {CFG, axiosHeader} from './CFG';
import {Alert} from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import {selectChangeHandler} from './CustomForm';

class TripLookup extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            loading: true,
            id: 0,
            list: new Array(),
            validator: {},
            search: ''
        };
    }

    componentDidMount(){
        this.get();
    }

     handleInputChange = (search, actionMeta) => {

         let REF = this;
        search = search.replace(/\W/g, '');

        if(actionMeta.action == 'set-value'){
            if(typeof REF.props.formcontrol === "function")
            setTimeout(() =>  REF.props.formcontrol(REF.state), 300 );
           
        }

        return search; 
      };

       filterResults = (inputValue) => {

        this.setState({ search: inputValue });

        let REF = this;

       setTimeout(() => REF.get(), 300);
        
        return REF.state.list.filter((v) => {
            
            if(REF.state.search == null)
            return false;

            let s = REF.state.search.toLowerCase();
            return v.label.toLowerCase().includes(s);
        });        
      };

     loadOptions = (search, callback) => {
        let REF = this;

            callback(REF.filterResults(search));
       
      };

      componentDidUpdate(prevProps, prevState){
     
        if(prevProps.id != this.props.id){
            this.setState({ id : this.props.id });
            this.setValue(this.props.id);
        }
       
        if(prevState.list != this.state.list && this.state.list.length > 0){
            this.setValue(this.state.id);
        }
    
    }

    setValue = (up_value) => {
        let REF = this;
        let find = REF.state.list.find((pair) => { return pair.value == up_value });
        if(typeof find !== "undefined"){
            setTimeout(() => REF.setState({ value: find }), 300);
        }   
    }

    onChange = (pair, actionMeta) => {
        this.setState({ value: pair, id: pair.value });
        this.props.change(pair,actionMeta);
    }

    render(){

        return (
            <>
            <AsyncSelect
            autoFocus={true}
            defaultOptions={true}
            cacheOptions
            onChange={this.onChange}
            onInputChange={this.handleInputChange}
            noOptionsMessage={() => "hmm.... experimenta mudar um pouco essa pesquisa"} 
            readOnly={typeof this.props.readonly !== "undefined"} 
            name="id"
            onSelectResetsInput={false}
        	onBlurResetsInput={false}
            value={this.state.value}
            isClearable={true}
            placeholder="pesquisar e selecionar" 
            id="f-produto" 
            loadOptions={ this.loadOptions }
            />
            </>
        );
    }

    get(){
        let REF = this; 

        let url = CFG.URL_API+'/trip?title={'+this.state.search+'}&selectMode=pluck&pluckKey=id&pluckValue=title';

        if(typeof this.props.start_at !== "undefined")
        url += '&start_at='+this.props.start_at;
        
        if(typeof this.props.end_at !== "undefined")
        url += '&end_at='+this.props.end_at;

      return axios.get(url, axiosHeader())
        .then(function(response){
            let entries = Object.entries(response.data);
       
            let htmlList = new Array();
          
            entries.map(([k,v]) => {
                htmlList.push({ value: k, label: ("( "+k+" )").padEnd(10)+" "+v});    
            });

            REF.setState({ list: htmlList, loading: false });

      })
        .catch(function(error){
         //requestException(error);
        });
    }

}

export default TripLookup;
