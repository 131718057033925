import React from 'react';
import './App.css';
import './bootstrap.min.css';

import Footer from './Footer.js';

import layoutMap from './layout/layoutMap';
import LayoutWide from './layout/LayoutWide';
import './App.scss';

import CFG from './CFG';
import { BrowserRouter as Router } from 'react-router-dom';

class App extends React.Component {

  render(){

  const LayoutSetup = layoutMap() == undefined ? LayoutWide : layoutMap();

    return (
               <Router basename={process.env.PUBLIC_URL}>

               <LayoutSetup/>
              
        <div className="fixed-bottom-placeholder"></div>
        <Footer></Footer>
        <div className="fixed-bottom">
   
        </div>
        </Router>
    );
  }

}

export default App;
